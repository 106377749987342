import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {array, bool, object, shape, string} from "prop-types"
import {
  FaUser as AccountIcon,
  FaStreetView as ContactIcon,
  FaMedal as ObjectiveIcon,
} from "react-icons/fa"
import {GiClick as InteractionsIcon} from "react-icons/gi"
import {MdClose as DeleteIcon} from "react-icons/md"

import {useTemplateContext} from "components/template-router/template-context"
import CampaignTargetingCondition from "components/template-targeting/campaign-targeting-condition"
import CardOnFileTargetingCondition from "components/template-targeting/card-on-file-targeting-condition"
import DirectDepositTargetingCondition from "components/template-targeting/direct-deposit-targeting-condition"
import JourneyReentryTargetingCondition from "components/template-targeting/journey-reentry-targeting-condition"
import RewardsTargetingCondition from "components/template-targeting/rewards-targeting-condition"
import SurveyTargetingCondition from "components/template-targeting/survey-targeting-condition"
import TargetingConditionTextfield from "components/template-targeting/targeting-condition-textfield"

import useFeatures from "lib/hooks/use-features"
import humanize from "lib/string/humanize"

import ComplexSelect from "../complex-select/complex-select"
import DOSelect from "../do-select/do-select"
import {useTemplateTargeting} from "./template-targeting-context"
import {
  availableAccountFields,
  availableContactFields,
  injectCustomOperators,
  isSurveyMultiAnswerAvailable,
  availableOperators as standardAvailableOperators,
} from "./template-targeting-helpers"

const useStyles = makeStyles(theme => ({
  fieldSelect: {
    width: 350,
  },
  fieldSelectMax: {
    maxWidth: 350,
  },
  in: {
    color: "#666",
    fontSize: 18,
    marginRight: 16,
    marginTop: theme.spacing(3),
  },
  labelLessInput: {
    marginTop: 16,
  },
  tooltip: {
    marginLeft: 10,
  },
  listSubheader: {
    backgroundColor: theme.palette.common.white,
    pointerEvents: "none",
  },
  listItemTextSecondary: {
    color: "#7a7b7f",
  },
  root: {
    alignItems: "flex-start",
    display: "flex",
    minHeight: 50,
    "&:hover $removeTargetingCondition": {
      opacity: 1,
      color: theme.palette.error.main,
    },
  },
  removeTargetingCondition: {
    opacity: 0,
    transition: theme.transitions.create("all"),
    marginLeft: theme.spacing(2),
  },
  inputField: {
    marginRight: theme.spacing(2),
  },
}))

export const conditionValidator = (operator, value) => {
  const availableOperators = standardAvailableOperators
  const fullOperator = availableOperators.find(({value: v}) => v === operator)
  if (fullOperator && fullOperator.hideValueInput) return true

  if (!value) return false

  if (operator === "greater_than" || operator === "less_than") {
    return /^-?\d+(\.?\d+)?$/.test(value)
  }

  if (
    ["within_last", "within_at_least", "within_next_x_days", "more_than_x_days_from_now"].includes(
      operator
    )
  )
    return /^\d+$/.test(value)

  return true
}

const getFullOperator = ({field, operator, meta}) => fullOperator => {
  // NB: `is_empty` (was not answered) and `is_not_empty` (was answered) does not prescribe to the
  // special delimited `::` syntax seen in the survey dynamic operators, so we filter them out here
  // and let the static `availableOperators` filter take over.
  // See `injectCustomOperators` in `template-targeting-helpers.js` for more details
  if (isSurveyMultiAnswerAvailable(field, meta) && !["is_empty", "is_not_empty"].includes(operator))
    return (
      fullOperator.value === `${operator}::${meta.answerTitle}` &&
      fullOperator.isAvailable(field, meta)
    )

  return fullOperator.value === operator && fullOperator.isAvailable(field, meta)
}

const TargetingCondition = ({
  cardOnFileTargetingValues,
  disabled,
  enrollmentContentBlocks,
  objectives,
  targetingCondition,
  templates,
  surveyContentBlocks,
  surveyQuestions,
}) => {
  const classes = useStyles()
  const {templateId} = useTemplateContext()
  const {updateTargetingCondition, removeTargetingCondition} = useTemplateTargeting()
  const {model, field, id, meta, metaSubKey, targetingGroupId, operator, value} = targetingCondition

  const availableOperators = injectCustomOperators(field, meta)

  // NB: `operator` really translates to 'operatorValue`
  // NB: fullOperator really translates to `operator`
  const fullOperator = availableOperators.find(getFullOperator(targetingCondition))
  const renderCondition = (
    {name, value, availableTypes} // eslint-disable-line react/prop-types
  ) => (
    <MenuItem key={value} value={value}>
      <ListItemText
        classes={{secondary: classes.listItemTextSecondary}}
        primary={name}
        secondary={availableTypes ? `Value can be a ${availableTypes}` : ""}
      />
    </MenuItem>
  )

  const onChange = ({target}) => {
    updateTargetingCondition({id, targetingGroupId, [target.name]: target.value})
  }

  // NB: "journey" model = "Interactions" in the UI
  // NB: "survey" and "card_on_file" are advanced targeting conditions that make use of meta
  const getJourneyConditionChanges = condition => {
    switch (condition.value) {
      // Check targetingCondition.field vs value.value
      case "journey_status":
        return {meta: {}, operator: "is_active", value: "any"}
      case "mobile_app_download":
        return {meta: {}, operator: "is_link_clicked", value: "any"}
      case "card_on_file":
        return {meta: {}, operator: "is_completed", value: "any"}
      case "direct_deposit":
        return {meta: {}, operator: "is_completed", value: "any"}
      case "enrollment":
        return {
          meta: {},
          metaSubKey: "any enrollment widget",
          operator: "is_accepted",
          value: "any",
        }
      case "opt_in":
        return {meta: {}, operator: "is_opted_in_email", value: "any"}
      case "survey":
        return {
          meta: {},
          operator: "is_started",
          value: "any",
        }
      case "journey_reentry":
        return {meta: {selectedTemplate: "any"}, operator: "is", value: "2"}
      case "rewards":
        return {}
      default:
        throw Error("Unknown action name: " + condition.field)
    }
  }

  // Validate condition state and enforce a sensible default when the model changes
  const onChangeField = complexSelect => {
    const condition = complexSelect.target.value
    const newModel = complexSelect.target.value.category
    const modelChanged = model !== newModel
    const nextTargetingCondition = {
      id,
      targetingGroupId,
      model: condition.category,
      field: condition.value,
    }

    // Special case: 'birthdate' is the only field from the 'contacts' column
    // that does not show the third "value" field.  If we don't set it, code
    // will assume a value field exists and throw an error.
    if ("birthdate" === condition.value) {
      nextTargetingCondition.operator = "is_today"
      nextTargetingCondition.value = null
    }

    // Note: Is this dead code? I think the condition object never has a `field` key.
    if ("birthdate" === condition.field) {
      nextTargetingCondition.operator = availableOperators.find(op =>
        op.isAvailable(value.value)
      ).value
    }

    if (condition.value === "rewards") {
      nextTargetingCondition.operator = null
      nextTargetingCondition.value = null
    }

    if (
      modelChanged ||
      // Reset to default when switching from email or phone_mobile fields with operators
      // related to verified, opted-in and authorized, which are not available
      // for other contact fields
      (![condition.value, condition.field].includes("birthdate") && newModel === "contact")
    ) {
      // Journey and Objective store internally-generated data, so we clear the "value"
      // and meta subkey when switching away since these don't make sense in other models
      // ex: value might be an action type string or objective id)
      if (["journey", "objective"].includes(newModel) || ["journey", "objective"].includes(model)) {
        nextTargetingCondition.value = null
        nextTargetingCondition.metaSubKey = null
      }
      // Pick a sensible default, while preserving the user-entered value when possible
      switch (newModel) {
        case "contact":
          nextTargetingCondition.operator = availableOperators.find(op =>
            op.isAvailable(condition.value)
          ).value
          break
        case "objective":
          nextTargetingCondition.operator = "is"
          nextTargetingCondition.value = "completed"
          break
        case "account":
          nextTargetingCondition.operator = availableOperators.find(op =>
            op.isAvailable(condition.value)
          ).value
          break

        case "journey":
          // NB: "Journey" is labeled as "interactions" in the UI
          // noop here, below we will reset the field since we always want to update
          // the journey info when these values when switching the field under journey
          break
        default:
          throw Error("Unknown model: " + newModel)
      }
    }

    // Get changes to journey condition and merge into next condition
    // We always want to reset the journey field even if the model didn't change
    if (newModel === "journey") {
      const journeyConditionChanges = getJourneyConditionChanges(condition)
      updateTargetingCondition({...nextTargetingCondition, ...journeyConditionChanges})
    } else {
      updateTargetingCondition(nextTargetingCondition)
    }
  }

  let fullCategoryList = [
    {category: "contact", icon: <ContactIcon />},
    {category: "objective", icon: <ObjectiveIcon />},
    {category: "account", icon: <AccountIcon />},
  ]

  const {hasFeature} = useFeatures()

  if (hasFeature("campaign-retargeting")) {
    fullCategoryList.push({category: "journey", icon: <InteractionsIcon />, label: "Interactions"})
  }

  return (
    <div className={classes.root}>
      {["is_empty", "is_not_empty"].includes(targetingCondition.operator) &&
      fullOperator.hideValueInput ? (
        <ComplexSelect
          categories={[
            {category: "contact", icon: <ContactIcon />},
            {category: "account", icon: <AccountIcon />},
          ]}
          classes={{root: cx(classes.fieldSelect, classes.inputField)}}
          disabled={disabled}
          label="Field"
          noMatchMessage="No targetable attributes matched"
          onChange={onChangeField}
          value={{category: model, value: field}}
          values={{
            account: availableAccountFields,
            contact: availableContactFields,
          }}
        />
      ) : (
        <ComplexSelect
          categories={fullCategoryList}
          classes={{root: cx(classes.fieldSelect, classes.inputField)}}
          disabled={disabled}
          label="Field"
          noMatchMessage="No targetable attributes matched"
          onChange={onChangeField}
          value={{category: model, value: field}}
          values={{
            account: availableAccountFields,
            contact: availableContactFields,
            objective: objectives,
            journey: [
              {name: "Journey Status", value: "journey_status", priority: true},
              {
                name: "Mobile App Download Actions",
                value: "mobile_app_download",
                priority: true,
              },
              {
                name: "Card on File Actions",
                value: "card_on_file",
                priority: true,
              },
              {
                name: "Direct Deposit Actions",
                value: "direct_deposit",
                priority: true,
              },
              hasFeature("enrollment-widget")
                ? {name: "Enrollment Actions", value: "enrollment", priority: true}
                : null,
              {
                name: "Opt-In Actions",
                value: "opt_in",
                priority: true,
              },
              {
                name: "Survey Actions",
                value: "survey",
                priority: true,
              },
              hasFeature("tactical-campaign-and-account-management")
                ? {
                    name: "Campaign Re-Enrollment Count",
                    value: "journey_reentry",
                    priority: true,
                  }
                : null,
              hasFeature("rewards") ? {name: "Rewards", value: "rewards", priority: true} : null,
            ].filter(item => item !== null),
          }}
        />
      )}
      {field.match(/meta_(public|private)/) && (
        <TextField
          className={classes.inputField}
          disabled={disabled}
          error={!metaSubKey}
          helperText={!metaSubKey && "required"}
          inputProps={{"data-testid": "meta-key-input"}}
          name="metaSubKey"
          label={`${humanize(field)} Key`}
          onChange={onChange}
          required={true}
          value={metaSubKey || ""}
        />
      )}
      {model === "objective" && (
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          name="operator"
          onChange={onChange}
          value={operator}
        >
          <MenuItem value="is">is completed</MenuItem>
          <MenuItem value="is_not">is not completed</MenuItem>
        </DOSelect>
      )}
      {model !== "objective" && (
        <>
          {/*Enrollment Actions*/}
          {model === "journey" && field === "enrollment" && !fullOperator.hideValueInput && (
            <>
              <span className={classes.in}>for</span>
              <FormControl classes={{root: classes.fieldSelect}} disabled={disabled}>
                <InputLabel htmlFor="journey-enrollment-actions">Enrollment Widget Name</InputLabel>
                <DOSelect
                  className={cx(classes.labelLessInput, classes.inputField)}
                  disabled={disabled}
                  id="journey-enrollment-actions"
                  name="metaSubKey"
                  onChange={onChange}
                  value={metaSubKey}
                >
                  {enrollmentContentBlocks.map(({name, value}) => (
                    <MenuItem key={value} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </DOSelect>
              </FormControl>
            </>
          )}
          {/* Survey Actions */}
          {model === "journey" && field === "survey" && (
            <SurveyTargetingCondition
              classes={classes}
              disabled={disabled}
              surveyContentBlocks={surveyContentBlocks}
              surveyQuestions={surveyQuestions}
              targetingCondition={targetingCondition}
              templateId={templateId}
              templates={templates}
            />
          )}
          {/* Card On File Actions */}
          {model === "journey" && field === "card_on_file" && (
            <CardOnFileTargetingCondition
              cardOnFileTargetingValues={cardOnFileTargetingValues}
              classes={classes}
              disabled={disabled}
              targetingCondition={targetingCondition}
              templateId={templateId}
              templates={templates}
              onChange={onChange}
            />
          )}
          {/* Direct Deposit Actions */}
          {model === "journey" && field === "direct_deposit" && (
            <DirectDepositTargetingCondition
              classes={classes}
              disabled={disabled}
              operators={availableOperators.filter(op => op.isAvailable(field, meta))}
              targetingCondition={targetingCondition}
              templateId={templateId}
              templates={templates}
              onChange={onChange}
            />
          )}

          {/* Journey Re-Rentry Actions */}
          {model === "journey" && field === "journey_reentry" && (
            <JourneyReentryTargetingCondition
              classes={classes}
              disabled={disabled}
              targetingCondition={targetingCondition}
              templateId={templateId}
              templates={templates}
              onChange={onChange}
            />
          )}

          {/* Rewards Actions */}
          {model === "journey" && field === "rewards" && (
            <RewardsTargetingCondition
              classes={classes}
              disabled={disabled}
              targetingCondition={targetingCondition}
              templateId={templateId}
              templates={templates}
            />
          )}

          {/* Catch-all for other actions */}
          {!["card_on_file", "direct_deposit", "survey", "journey_reentry", "rewards"].includes(
            field
          ) && (
            <FormControl classes={{root: classes.fieldSelect}} disabled={disabled}>
              {model === "journey" && <InputLabel htmlFor="journey-value">Value</InputLabel>}
              <DOSelect
                className={cx(classes.labelLessInput, classes.inputField)}
                disabled={disabled}
                onChange={onChange}
                name="operator"
                renderValue={value =>
                  availableOperators.find(o => o.isAvailable(field, meta) && o.value === value)
                    ?.name ?? availableOperators.find(o => o.isAvailable(field, meta))?.name
                }
                value={operator}
              >
                {availableOperators.filter(op => op.isAvailable(field, meta)).map(renderCondition)}
              </DOSelect>
            </FormControl>
          )}

          {model === "journey" &&
          !["card_on_file", "survey", "journey_reentry", "rewards"].includes(field) &&
          !fullOperator.hideValueInput ? (
            <CampaignTargetingCondition
              classes={classes}
              disabled={disabled}
              name="value"
              onChange={onChange}
              templateId={templateId}
              templates={templates}
              value={value}
            />
          ) : (
            !["card_on_file", "survey", "journey_reentry", "rewards"].includes(field) &&
            !fullOperator.hideValueInput && (
              <TargetingConditionTextfield
                classes={classes}
                disabled={disabled}
                getFullOperator={getFullOperator(targetingCondition)}
                name="value"
                onChange={onChange}
                targetingCondition={targetingCondition}
                value={value || ""}
              />
            )
          )}
        </>
      )}
      {!disabled && (
        <Tooltip title="Remove targeting condition">
          <IconButton
            aria-label="Remove targeting condition"
            className={classes.removeTargetingCondition}
            onClick={() => removeTargetingCondition(targetingCondition)}
            size="medium"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

TargetingCondition.propTypes = {
  cardOnFileTargetingValues: object,
  disabled: bool,
  enrollmentContentBlocks: array,
  surveyContentBlocks: array,
  surveyQuestions: array,
  targetingCondition: shape({
    id: string,
    model: string,
    field: string,
    meta: object,
    meta_sub_key: string,
    operator: string,
    value: string,
  }),
  templates: array,
  objectives: array,
}

export default TargetingCondition
