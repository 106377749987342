import {Button, Typography} from "@mui/material"
import {bool, func, object, string} from "prop-types"
import {useCallback, useEffect, useRef, useState} from "react"
import {MdCheckCircle} from "react-icons/md"
import shortid from "shortid"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {fetchJourneySurveyStatus} from "lib/api"
import useAnalytics from "lib/hooks/use-analytics"

import BrandedButton from "../../../branded-button/branded-button"
import {journeyContext} from "../../../journeys/journey-context"
import MuiIcon from "../../../mui-icon"
import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import {buttonColors} from "../helpers/button-colors"
import {SURVEY_ANSWER_COMPONENT_MAP} from "./survey-answer-components"
import {
  answerHasErrors,
  getQuestionAnsweredTrackingBody,
  getSurveyCompletedTrackingBody,
} from "./survey-readonly-helpers"

const SurveyReadonly = ({
  className,
  contentBlock,
  customCss,
  journeyId,
  onClick,
  style,
  themeClasses,
}) => {
  const {iconSrc} = contentBlock.data
  const {track} = useAnalytics()
  const [isCompleted, setIsCompleted] = useState(false)
  const [questions, setQuestions] = useState(contentBlock.data.questions)
  const [currentQuestion, setCurrentQuestion] = useState(contentBlock.data.questions[0])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [currentAnswer, setCurrentAnswer] = useState(null)

  useEffect(() => {
    if (journeyId) {
      fetchJourneySurveyStatus(journeyId, contentBlock.id).then(answers => {
        if (answers.length > 0) {
          let allQuestionsHaveAnswers = true
          const questionsWithAnswers = contentBlock.data.questions.map(question => {
            const correspondingAnswer = answers.find(
              answer => answer.questionSlug === question.slug
            )
            if (correspondingAnswer) {
              const finalAnswer =
                question.type === "DATE"
                  ? new Date(correspondingAnswer.answer)
                  : correspondingAnswer.answer
              return {...question, answer: finalAnswer}
            } else {
              allQuestionsHaveAnswers = false
              return question
            }
          })
          setIsCompleted(allQuestionsHaveAnswers)
          setQuestions(questionsWithAnswers)
          setCurrentQuestion(questionsWithAnswers[0])
        }
      })
    }
  }, [contentBlock.id, contentBlock.data.questions, journeyId])

  const aria = useRef({id: shortid.generate()}).current

  const generateSurveyState = currentQuestionWithAnswer => {
    const questionsWithNewAnswer = questions.map((question, index) =>
      index === currentQuestionIndex ? currentQuestionWithAnswer : question
    )
    return {
      contentBlockId: contentBlock.id,
      contentContainerId: contentBlock.contentContainerId,
      currentQuestion: currentQuestionWithAnswer,
      currentQuestionIndex,
      isCompleted,
      journeyId,
      questions: questionsWithNewAnswer,
      slug: contentBlock.slug,
    }
  }

  const onChange = useCallback(newValue => setCurrentAnswer(newValue), [])

  if (isCompleted)
    return (
      <div
        aria-atomic="true"
        aria-labelledby={`survey-${contentBlock.slug}-completion-message`}
        aria-live="polite"
        className={className}
        data-testid="survey-root"
        onClick={onClick}
        role="status"
      >
        <style>{customCss}</style>
        <div className={themeClasses.surveySuccessMessage}>
          <MuiIcon
            className={themeClasses.surveySuccessIcon}
            icon={<MdCheckCircle aria-hidden="true" color="primary" />}
          />
          <Typography id={`survey-${contentBlock.slug}-completion-message`}>
            Thank you for completing the survey!
          </Typography>
        </div>
      </div>
    )

  if (!currentQuestion)
    return (
      <div className={className} data-testid="survey-root" onClick={onClick} style={style}>
        <style>{customCss}</style>
        <Typography component="em">No questions yet...</Typography>
      </div>
    )

  const AnswerInputComponent = SURVEY_ANSWER_COMPONENT_MAP[currentQuestion.type]

  const onNext = () => {
    const updatedCurrentQuestion = {...currentQuestion, answer: currentAnswer}
    if (!answerHasErrors(updatedCurrentQuestion)) {
      track(
        "survey_question_answered",
        getQuestionAnsweredTrackingBody(generateSurveyState(updatedCurrentQuestion))
      )
      const nextQuestionIndex = currentQuestionIndex + 1
      const nextQuestion = questions[nextQuestionIndex]
      const nextAnswer = nextQuestion.answer || null
      setCurrentQuestion(nextQuestion)
      setCurrentQuestionIndex(nextQuestionIndex)
      setCurrentAnswer(nextAnswer)
    }
  }

  const onPrev = () => {
    const previousQuestionIndex = currentQuestionIndex - 1
    const previousQuestion = questions[previousQuestionIndex]
    const previousAnswer = previousQuestion.answer || null
    setCurrentQuestion(previousQuestion)
    setCurrentQuestionIndex(previousQuestionIndex)
    setCurrentAnswer(previousAnswer)
  }

  const onSubmit = () => {
    const updatedCurrentQuestion = {...currentQuestion, answer: currentAnswer}
    if (!answerHasErrors(updatedCurrentQuestion)) {
      track(
        "survey_question_answered",
        getQuestionAnsweredTrackingBody(generateSurveyState(updatedCurrentQuestion))
      )
      track(
        "survey_completed",
        getSurveyCompletedTrackingBody(generateSurveyState(updatedCurrentQuestion))
      )
      setIsCompleted(true)
    }
  }

  const showPreviousButton = currentQuestionIndex !== 0
  const showNextButton = currentQuestionIndex < questions.length - 1
  const showSubmitButton = currentQuestionIndex === questions.length - 1

  return (
    <div className={className} data-testid="survey-root" onClick={onClick} style={style}>
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <Typography className={themeClasses.surveyQuestionTitle} id={aria.id}>
        {currentQuestion.title}
      </Typography>

      <div data-testid="survey-step">
        <AnswerInputComponent
          aria-labelledby={aria.id}
          key={currentQuestion.slug}
          onChange={onChange}
          question={currentQuestion}
          themeClasses={themeClasses}
        />

        <div className={themeClasses.surveyNav}>
          {showPreviousButton && (
            <Button
              aria-label="Go back to previous question"
              className="prev-page"
              onClick={onPrev}
              type="button"
            >
              prev
            </Button>
          )}

          {showNextButton && (
            <Button
              aria-label="Go to next question"
              className="next-or-submit"
              onClick={onNext}
              variant="contained"
            >
              Next
            </Button>
          )}

          {showSubmitButton && (
            <BrandedButton
              aria-label="Submit survey"
              className="next-or-submit"
              color="primary"
              onClick={onSubmit}
              style={buttonColors(contentBlock.data)}
              variant="contained"
            >
              Submit
              {iconSrc && <img alt="" aria-hidden="true" className="icon" src={iconSrc} />}
            </BrandedButton>
          )}
        </div>
      </div>
    </div>
  )
}

SurveyReadonly.propTypes = {
  className: string,
  contact: object,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  isCompleted: bool,
  journeyId: string,
  onClick: func,
  style: object,
  themeClasses: object.isRequired,
}

export default contentBlockEditorConsumer(journeyContext(SurveyReadonly))
