import {IconButton, InputAdornment, TextField} from "@mui/material"
import {func, object} from "prop-types"
import {useState} from "react"
import {MdVisibilityOff as HidePasswordIcon, MdVisibility as ShowPasswordIcon} from "react-icons/md"

import {matchesPassword, validPassword} from "lib/field-validations"
import {useForm} from "lib/hooks/use-form"

import SaveButton from "../save-button/save-button"

const AccountPasswordSettingsForm = ({classes, passwordPolicy, onSubmit}) => {
  const {field, handleSubmit, submitting} = useForm({
    enableReinitialize: true,
    validators: {
      password: [validPassword(passwordPolicy)],
      passwordConfirmation: [matchesPassword],
    },
    onSubmit,
  })
  const [showPasswords, setShowPasswords] = useState(false)
  const toggleShowPassword = () => setShowPasswords(s => !s)

  const password = field("password")

  return (
    <form className="account-password-settings-form" onSubmit={handleSubmit}>
      <TextField
        autoComplete="current-password"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={toggleShowPassword}
                tabIndex={-1}
                size="medium"
              >
                {showPasswords ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Current Password"
        margin="normal"
        type={showPasswords ? "text" : "password"}
        {...field("currentPassword")}
      />
      <TextField
        autoComplete="new-password"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={toggleShowPassword}
                tabIndex={-1}
                size="medium"
              >
                {showPasswords ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="New Password"
        margin="normal"
        type={showPasswords ? "text" : "password"}
        {...password}
        helperText={
          password.helperText ? (
            <ul>
              {password.helperText.map(helperText => (
                <li key={helperText}>{helperText}</li>
              ))}
            </ul>
          ) : null
        }
      />
      <TextField
        autoComplete="new-password"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={toggleShowPassword}
                tabIndex={-1}
                size="medium"
              >
                {showPasswords ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Repeat Password"
        margin="normal"
        type={showPasswords ? "text" : "password"}
        {...field("passwordConfirmation")}
      />
      <div className={classes.formActions}>
        <SaveButton submitting={submitting} />
      </div>
    </form>
  )
}

AccountPasswordSettingsForm.propTypes = {
  classes: object,
  onSubmit: func.isRequired,
  passwordPolicy: object.isRequired,
}

export default AccountPasswordSettingsForm
