import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material"
import {alpha} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import {EditorState, Modifier} from "draft-js"
import {isEqual, snakeCase, uniqWith} from "lodash"
import {arrayOf, bool, func, node, object, oneOfType, string} from "prop-types"
import React, {Component} from "react"

import DangerButton from "components/danger-button/danger-button"
import DOSelect from "components/do-select/do-select"
import {previewContext} from "components/templates/preview-context"

import {contentTypeProp, editorContext, isPage} from "contexts/editor-context"
import {fetchKnownMetaKeys, fetchRewardSets, fetchSurveys} from "lib/api"
import {findBlockByEntity, getEntitySelectionRange} from "lib/draft-js/entity-helpers"
import {featurify} from "lib/hooks/use-features"
import {sessionStore as storage} from "lib/storage"

import PersonalizationSelector from "./personalization-selector"

const INIT_META_KEYS = {account: [], contact: []}
export const PERSONALIZATION_ENTITY = "PERSONALIZATION"

// Maintains the integrity of the parent meta keys while transforming the child keys.
const reduceIntoMetaKeys = (metaKeys, transform) =>
  Object.entries(metaKeys).reduce(
    (acc, [metaKey, keys]) => ({...acc, [metaKey]: transform(keys, metaKey)}),
    {}
  )

const allowedMetaKeysForContentType = (contentType, metaKeys) =>
  reduceIntoMetaKeys(metaKeys, keys =>
    isPage(contentType) ? [...keys] : keys.filter(({type}) => type !== "meta_private")
  )

const isSurveyPersonalization = value => !!value.match(/^survey::/)

const splitPersonalization = state => {
  const {selectedQuestionTitle, value} = state
  return isSurveyPersonalization(value)
    ? [...value.split("::"), selectedQuestionTitle]
    : value.split(".")
}

class PersonalizationEntityEditable extends Component {
  state = {
    selectedPersonalization: undefined,
    defaultValue: "",
    newPersonalization: "",
    newPersonalizationType: "meta_public",
    showNewPersonalizationField: false,
    allKnownMetaKeys: INIT_META_KEYS,
    allowedMetaKeys: INIT_META_KEYS,
    value: "",

    // Survey state
    selectedQuestionTitle: null,
    questionTitles: null,
    surveyFields: [],

    rewardSets: [],
  }

  componentDidMount() {
    const {contentType, hasFeature, isPreviewMode} = this.props

    // Set initial state based on personalization type, contact or survey
    this.setState(this.getInitialState())

    if (!isPreviewMode) {
      fetchKnownMetaKeys()
        .then(knownMetaKeys => {
          const localMetaKeys = this.getLocalMetaKeys()

          const allKnownMetaKeys = reduceIntoMetaKeys(knownMetaKeys, (knownKeys, metaKey) =>
            uniqWith(
              [
                ...Object.entries(knownKeys).reduce(
                  (acc, [type, keys]) => [...acc, ...keys.map(key => ({type, key}))],
                  []
                ),
                ...localMetaKeys[metaKey],
              ],
              isEqual
            )
          )

          this.setState({
            allKnownMetaKeys,
            allowedMetaKeys: allowedMetaKeysForContentType(contentType, allKnownMetaKeys),
          })
        })
        .catch(() => {})

      if (hasFeature("campaign-retargeting")) this.fetchSurveys()
      this.fetchRewards()
    }
  }

  fetchSurveys() {
    fetchSurveys().then(surveys => {
      const surveyFields = surveys.reduce((acc, {data: {name, questions}}) => {
        const index = acc.findIndex(
          survey => survey.name.trim().toLowerCase() === name.trim().toLowerCase()
        )

        if (index !== -1) {
          // Append current questions to existing survey item to simulate "atomized" widgets
          return acc.map((survey, i) => {
            if (index === i) {
              return {
                ...survey,
                questionTitles: {
                  ...survey.questionTitles,
                  ...questions.reduce(
                    (accQuestionTitles, {title}) => ({...accQuestionTitles, [title]: true}),
                    {}
                  ),
                },
              }
            }

            return survey
          })
        }

        return [
          ...acc,
          {
            category: "survey",
            name,
            questionTitles: {
              ...questions.reduce(
                (accQuestionTitles, {title}) => ({...accQuestionTitles, [title]: true}),
                {}
              ),
            },
            value: `survey::${name}`,
          },
        ]
      }, [])
      this.setState(
        {
          surveyFields: surveyFields.map(field => ({
            ...field,
            questionTitles: Object.keys(field.questionTitles),
          })),
        },
        () => {
          // The questionTitles related to a selected survey are not populated at mount,
          // so source and set them here to keep things in-sync
          if (this.state.surveyFields) {
            const field = this.state.surveyFields.find(field => field.value === this.state.value)
            this.setState({questionTitles: field?.questionTitles})
          }
        }
      )
    })
  }

  fetchRewards() {
    fetchRewardSets().then(rewardSets => {
      this.setState({
        rewardSets: rewardSets.map(rewardSet => ({
          name: rewardSet.name,
          value: `rewards.rewardSets.${rewardSet.id}`,
        })),
      })
    })
  }

  getInitialState() {
    const {contentState, entityKey} = this.props
    const {value, defaultValue = ""} = contentState.getEntity(entityKey).getData()

    if (isSurveyPersonalization(value)) {
      // For survey fields, they are composed of three path parts--subject, survey title
      // and question title--which are held here via the value and questionTitle state properties
      const [subject, surveyTitle, questionTitle] = value.split("::")
      return {
        defaultValue,
        selectedQuestionTitle: questionTitle,
        value: `${subject}::${surveyTitle}`,
      }
    }

    // isContactPersonalization
    return {value, defaultValue}
  }

  onSelectPersonalization = ({
    target: {
      value: {value},
    },
  }) => {
    switch (true) {
      case ["custom-account", "custom-contact"].includes(value):
        this.setState({
          selectedQuestionTitle: null,
          showNewPersonalizationField: true,
          // Only fields in the survey category should have questionTitles
          questionTitles: null,
          value,
        })
        break
      case isSurveyPersonalization(value):
        const surveyField = this.state.surveyFields.find(field => field.value === value)
        this.setState({
          newPersonalization: "",
          selectedQuestionTitle: surveyField.questionTitles[0],
          showNewPersonalizationField: false,
          questionTitles: surveyField.questionTitles,
          value,
        })
        break
      default:
        this.setState({
          newPersonalization: "",
          selectedQuestionTitle: null,
          showNewPersonalizationField: false,
          // Only fields in the survey category should have questionTitles
          questionTitles: null,
          value,
        })
    }
  }

  onChangeDefaultValue = ({target: {value: defaultValue}}) => {
    this.setState({defaultValue})
  }

  onChangeNewPersonalizationKey = ({target: {value: newPersonalization}}) => {
    this.setState({newPersonalization})
  }

  onSelectPersonalizationType = ({target: {value: newPersonalizationType}}) => {
    this.setState({newPersonalizationType})
  }

  updateEditorState = () => {
    const {entityKey, contentType} = this.props
    const {
      defaultValue,
      allKnownMetaKeys,
      newPersonalization,
      newPersonalizationType,
      showNewPersonalizationField,
    } = this.state
    let value = this.state.value
    let replacementText = ""

    if (!showNewPersonalizationField) {
      const [subject, ...paramParts] = splitPersonalization({...this.state, value})

      // we only snake_case the first item.  we don't want to make any assumptions
      // about the case of the keys in meta_public
      replacementText = `#${subject.toUpperCase()}.${paramParts
        .map((p, i) => (i > 0 ? p : snakeCase(p).toUpperCase()))
        .join(".")}`
    } else {
      const localMetaKeys = this.getLocalMetaKeys()
      const newLocalMetaKeys = reduceIntoMetaKeys(localMetaKeys, localKeys =>
        uniqWith([...localKeys, {key: newPersonalization, type: newPersonalizationType}], isEqual)
      )
      const updatedAllKnownMetaKeys = reduceIntoMetaKeys(allKnownMetaKeys, (keys, metaKey) =>
        uniqWith([...keys, ...newLocalMetaKeys[metaKey]], isEqual)
      )

      storage.setItem("metaLocalKeys", JSON.stringify(newLocalMetaKeys))
      const customDataSource = value.split("-")[1]
      replacementText = `#${customDataSource.toUpperCase()}.${newPersonalizationType.toUpperCase()}.${newPersonalization}`
      value = `${customDataSource}.${newPersonalizationType}.${newPersonalization}`

      this.setState({
        value,
        allKnownMetaKeys: updatedAllKnownMetaKeys,
        allowedMetaKeys: allowedMetaKeysForContentType(contentType, updatedAllKnownMetaKeys),
        newPersonalization: "",
        showNewPersonalizationField: false,
      })
    }

    this.props.onEditorUpdate(pageEditorState => {
      const contentState = pageEditorState.getCurrentContent()
      const entitySelectionRange = getEntitySelectionRange(contentState, entityKey)
      const block = findBlockByEntity(contentState, entityKey)
      const inlineStyles = block.getInlineStyleAt(entitySelectionRange.getAnchorOffset())
      let updatedContentState = contentState.createEntity(PERSONALIZATION_ENTITY, "IMMUTABLE", {
        value: isSurveyPersonalization(value)
          ? `${value}::${this.state.selectedQuestionTitle}`
          : value,
        defaultValue,
      })
      const newEntityKey = updatedContentState.getLastCreatedEntityKey()

      // Why remove and insert? Well, it seems that when replacing text and
      // updating the entityKey on data through Modifier.applyEntity the
      // entityRanges don't seem to update. So it seems the simpler approach is to
      // just remove the existing text, collapse the selection range, and then
      // insert the new text.

      updatedContentState = Modifier.removeRange(contentState, entitySelectionRange, "backward")
      updatedContentState = Modifier.insertText(
        updatedContentState,
        entitySelectionRange.merge({
          focusOffset: entitySelectionRange.getAnchorOffset(),
          hasFocus: true,
        }),
        replacementText,
        inlineStyles,
        newEntityKey
      )

      updatedContentState = updatedContentState.replaceEntityData(entityKey, {value, defaultValue})

      return EditorState.push(pageEditorState, updatedContentState, "apply-entity")
    })
  }

  getLocalMetaKeys = () => JSON.parse(storage.getItem("metaLocalKeys")) || INIT_META_KEYS

  onRemoveSettings = () => {
    this.props.onEditorUpdate(editorState => {
      const selectionState = getEntitySelectionRange(this.props.contentState, this.props.entityKey)

      const newContentState = Modifier.removeRange(
        editorState.getCurrentContent(),
        selectionState,
        "forward"
      )

      return EditorState.forceSelection(
        EditorState.push(editorState, newContentState, "apply-entity"),
        selectionState.merge({
          focusKey: selectionState.getAnchorKey(),
          focusOffset: selectionState.getAnchorOffset(),
        })
      )
    })

    this.props.setReadOnly(false)
  }

  onCancelSettings = () => {
    const {contentState, entityKey} = this.props
    const {value, defaultValue = ""} = contentState.getEntity(entityKey).getData()

    this.setState({
      value,
      defaultValue,
      personalizationAnchor: null,
      newPersonalization: "",
      showNewPersonalizationField: false,
    })
    this.props.setReadOnly(false)
  }

  onSaveSettings = () => {
    this.setState({personalizationAnchor: null})
    this.props.setReadOnly(false)
    this.updateEditorState()
  }

  onOpenSettings = ({currentTarget}) => {
    this.props.setReadOnly(true)
    this.setState({personalizationAnchor: currentTarget})
  }

  render() {
    const {
      personalizationAnchor,
      value,
      defaultValue,
      newPersonalization,
      newPersonalizationType,
      showNewPersonalizationField,
      allowedMetaKeys,
    } = this.state
    const {classes, contentType} = this.props
    const showPersonalizationSettings = Boolean(personalizationAnchor)

    const replacedText = React.Children.map(this.props.children, child => {
      if (!!child.props?.text && child.props.text.includes("#REWARDS.REWARD_SETS")) {
        const uuid = child.props.text.split(".").pop()
        const matchedRewardSet = this.state.rewardSets.find(rewardSet => {
          return rewardSet?.value?.split(".")?.pop() === uuid
        })
        const text = `#REWARD_SET.${matchedRewardSet?.name}`
        return matchedRewardSet ? React.cloneElement(child, {text: text}) : null
      }
      return child
    })

    // setting onClick = null
    // otherwise, draft gets confused and calls the DraftEditorLeaf (https://github.com/facebook/draft-js/blob/e2c5357734de2a66025825c2872cc236a154d60c/src/component/contents/DraftEditorLeaf.react.js)
    // setSelection in an infinite loop.
    return (
      <>
        <span
          className={classes.personalization}
          onClick={showPersonalizationSettings ? null : this.onOpenSettings}
        >
          {replacedText}
        </span>
        <Popover
          anchorEl={personalizationAnchor}
          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
          onClose={this.onCloseSettings}
          open={showPersonalizationSettings}
          transformOrigin={{vertical: "top", horizontal: "left"}}
        >
          <div className={classes.settings}>
            <FormControl fullWidth={true}>
              <PersonalizationSelector
                allowedMetaKeys={allowedMetaKeys}
                isPage={isPage(contentType)}
                onChange={this.onSelectPersonalization}
                surveyFields={this.state.surveyFields}
                rewardSets={this.state.rewardSets}
                value={value}
              />
            </FormControl>
            {isSurveyPersonalization(this.state.value) && this.state.questionTitles && (
              <FormControl fullWidth={true}>
                <InputLabel id="personalization-questionTitle-label">Question</InputLabel>
                <DOSelect
                  id="personalization-questionTitle-select"
                  labelId="personalization-questionTitle-label"
                  onChange={({target: {value}}) => this.setState({selectedQuestionTitle: value})}
                  sx={{"& .MuiSelect-select": {padding: 0}}}
                  value={this.state.selectedQuestionTitle}
                >
                  {this.state.questionTitles.map(question => (
                    <MenuItem key={question} value={question}>
                      <ListItemText primary={question} />
                    </MenuItem>
                  ))}
                </DOSelect>
              </FormControl>
            )}
            {showNewPersonalizationField && (
              <>
                {
                  <FormControl className={classes.metadataSource} component="fieldset">
                    <FormLabel component="legend">Personalization Source</FormLabel>
                    <RadioGroup
                      classes={{root: classes.radioGroup}}
                      onChange={this.onSelectPersonalizationType}
                      value={newPersonalizationType}
                    >
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Public Metadata"
                        value="meta_public"
                      />
                      <Tooltip
                        title={
                          isPage(contentType)
                            ? ""
                            : "Private Metadata cannot be used for personalizations in SMS or Emails"
                        }
                      >
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          disabled={!isPage(contentType)}
                          label="Private Metadata"
                          value="meta_private"
                        />
                      </Tooltip>
                    </RadioGroup>
                  </FormControl>
                }
                <TextField
                  autoFocus={true}
                  fullWidth={true}
                  label="New Personalization Key"
                  onChange={this.onChangeNewPersonalizationKey}
                  value={newPersonalization}
                />
                <FormHelperText className={classes.helperText} margin="dense">
                  Create a new personalization key relative to the contact's{" "}
                  <code>{`${newPersonalizationType}`}</code>.
                </FormHelperText>
              </>
            )}
            <TextField
              autoFocus={true}
              fullWidth={true}
              label="Default Value"
              onChange={this.onChangeDefaultValue}
              value={defaultValue}
            />
            <FormHelperText className={classes.helperText} margin="dense">
              If the personalization you have chosen is not available in the data supplied for this
              journey, we will use the <strong>Default Value</strong>.
            </FormHelperText>
            {
              <FormHelperText className={classes.lightAuthWarning}>
                You should never enter anything private for a default value even if it is for a
                private personalization.
              </FormHelperText>
            }
            <div className={classes.actions}>
              <DangerButton className={classes.pushLeft} onClick={this.onRemoveSettings}>
                Remove
              </DangerButton>
              <Button onClick={this.onCancelSettings}>Cancel</Button>
              <Button
                color="primary"
                disabled={
                  isSurveyPersonalization(this.state.value) && !this.state.selectedQuestionTitle
                }
                onClick={this.onSaveSettings}
              >
                Save
              </Button>
            </div>
          </div>
        </Popover>
      </>
    )
  }
}

PersonalizationEntityEditable.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  classes: object,
  contentState: object.isRequired,
  contentType: contentTypeProp,
  entityKey: string.isRequired,
  hasFeature: func.isRequired,
  isPreviewMode: bool,
  onEditorUpdate: func,
  setReadOnly: func,
}

const styles = theme => ({
  personalization: {
    backgroundColor: alpha(theme.palette.primary.light, 0.05),
    color: theme.palette.primary.main,
    padding: 2,
    fontSize: "inherit",
    cursor: "pointer",
  },
  settings: {
    width: 375,
    padding: 10,
  },
  helperText: {
    marginTop: 10,
  },
  actions: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
  pushLeft: {
    marginRight: "auto",
  },
  newPersonalizationIcon: {
    marginRight: theme.spacing(1),
  },
  metadataSource: {
    marginTop: theme.spacing(2),
  },
  radioGroup: {
    flexDirection: "row",
  },
  lightAuthWarning: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.error.main}`,
  },
})

export default withStyles(styles)(
  previewContext(editorContext(featurify(PersonalizationEntityEditable)))
)
