import * as Sentry from "@sentry/browser"
import {stringify} from "query-string"

import {toCamelCase, toSnakeCase} from "lib/case-converter"

import {clearAccessToken, getAccessToken} from "./access-token"
import HttpError from "./http-error"
import refreshAccessToken from "./refresh-token"
import storage from "./storage"

const injectDefaults = list => ["_type", ...(Array.isArray(list) ? list : [])]

const queryString = ({skipSnakeCase, queryParams, ignoreCaseConversionForKeys}) => {
  if (!queryParams) return ""
  const params = skipSnakeCase
    ? queryParams
    : toSnakeCase(queryParams, {ignoreKeys: injectDefaults(ignoreCaseConversionForKeys)})

  return `?${stringify(params, {arrayFormat: "bracket"})}`
}

const apiReq = async (path, options = {}, isRetry = false) => {
  const apiUrl = process.env.REACT_APP_API_URL

  const reqOptions = {
    ...options,
    headers: {
      ...options.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }

  if (reqOptions && reqOptions.body && !reqOptions.skipJSON) {
    const body = reqOptions.skipSnakeCase
      ? reqOptions.body
      : toSnakeCase(reqOptions.body, {
          ignoreKeys: injectDefaults(reqOptions.ignoreCaseConversionForKeys),
          ignorePaths: reqOptions.ignoreCaseConversionForPaths,
        })

    reqOptions.body = JSON.stringify(body)
  }

  const url = `${path.includes("oauth2") ? apiUrl.replace("/v1", "") : apiUrl}/${path}${queryString(
    reqOptions
  )}`
  let {accessToken, expiration, refreshToken} = getAccessToken(storage)

  try {
    if (
      expiration &&
      !!refreshToken &&
      path !== "oauth2/token" && // when we are getting/refreshing skip
      expiration < Date.now()
    ) {
      Sentry.addBreadcrumb({
        message: "REFRESHING AUTH TOKEN",
        level: "debug",
        data: {
          expiration,
          hasAccessToken: !!accessToken,
          hasRefreshToken: !!refreshToken,
        },
      })
      const {accessToken: newAccessToken} = await refreshAccessToken(refreshToken)
      accessToken = newAccessToken
    }

    if (accessToken)
      reqOptions.headers = {
        ...reqOptions.headers,
        Authorization: `Bearer ${accessToken}`,
      }

    const response = await window.fetch(url, reqOptions)

    if (reqOptions.method === "HEAD" && response.ok) {
      return [null, response]
    } else if (response.status === 204) {
      return options.withFetchResponse ? [null, response] : null
    } else if (response.status === 404 && response.url.includes("/v1/journeys/slug")) {
      // The inclusion of the journey specific URL string check above is to isolate this change
      // to a particular route, thereby minimizing the risk in altering auth processing.
      clearAccessToken(storage)
      return apiReq(path, options, true)
    } else if (response.ok) {
      let transformedBody = await response.json()

      if (!options.skipCamelCase)
        transformedBody = toCamelCase(transformedBody, {
          ignoreKeys: injectDefaults(reqOptions.ignoreCaseConversionForKeys),
          ignorePaths: reqOptions.ignoreCaseConversionForPaths,
        })

      return options.withFetchResponse ? [transformedBody, response] : transformedBody
    } else {
      const errorResponse = await response.json()

      throw new HttpError(response.status, errorResponse)
    }
  } catch (error) {
    if (
      error.message === "invalid_token" ||
      error.message === "invalid_request" ||
      error.status === 401
    ) {
      if (!isRetry && !!refreshToken) return apiReq(path, options, true)

      clearAccessToken(storage)
      if (window.location.pathname.match(/^\/admin\//)) {
        if (window.location.pathname !== "/admin/login")
          window.location = `/admin/login?redirect=${window.location.pathname}`

        // throw again here
        // this avoids any promises calling their
        // `then` method, and displaying an error message
        // when we're just going to punt them to the login
        throw error
      }
    } else {
      throw error
    }
  }
}

export default apiReq

if (process.env.NODE_ENV === "development") window.apiReq = apiReq
