import {Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {bool, func} from "prop-types"
import {Component} from "react"
import {MdError as ErrorIcon} from "react-icons/md"

import {fetchCurrentTeam} from "lib/api"

import Box from "../box/box"

class Validator extends Component {
  state = {}

  componentDidMount = () => this.testTeam()

  testTeam = () =>
    fetchCurrentTeam().catch(err => {
      if (err.status === 404) return this.setState({invalidSubdomain: true})
      throw err
    })

  render() {
    const {invalidSubdomain} = this.state

    return <ValidateSubdomain invalidSubdomain={invalidSubdomain} {...this.props} />
  }
}

const StyledDiv = styled("div")(({theme}) => ({
  display: "flex",
  minHeight: "100vh",
  justifyContent: "center",
  alignItems: "center",
  "& .content": {
    width: 355,
  },
  "& .logo": {
    width: 246,
    marginBottom: 30,
  },
  "& .title": {
    background: theme.palette.error.main,
    color: "#fff",
    margin: "-20px -20px 20px",
    padding: 20,
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    verticalAlign: "baseline",
  },
  "& .icon": {
    marginRight: 15,
  },
  "& .link": {
    display: "inline",
  },
}))

export function ValidateSubdomain({WrappedComponent, invalidSubdomain, ...props}) {
  if (!invalidSubdomain) return <WrappedComponent {...props} />

  return (
    <StyledDiv>
      <div className="content">
        <img alt="" className="logo" src="https://uploads.digitalonboarding.com/do_logo_long.png" />
        <Box>
          <div className="title">
            <ErrorIcon className="icon" size={30} /> This domain is not used
          </div>
          <Typography>
            Please check the url above and make sure you entered your team's address correctly.{" "}
            <br />
            <br />
            If you are not sure what your team's domain is please contact{" "}
            <Typography
              className="link"
              component="a"
              href={`mailto:support@digitalonboarding.com`}
            >
              support@digitalonboarding.com
            </Typography>
            .
          </Typography>
        </Box>
      </div>
    </StyledDiv>
  )
}

ValidateSubdomain.propTypes = {
  WrappedComponent: func.isRequired,
  invalidSubdomain: bool,
}

const validateSubdomain = WrappedComponent => props => (
  <Validator {...props} WrappedComponent={WrappedComponent} />
)

export default validateSubdomain
