import {Grid, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import arrayMove from "array-move"
import {useEffect, useState} from "react"
import {FaArrowRight as LinkIcon} from "react-icons/fa"
import {MdGroupWork as GroupWorkIcon} from "react-icons/md"
import {Link} from "react-router-dom"
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc"

import {fetchSplitTestingGroups, fetchTemplates, sortTemplates} from "lib/api"

import Box from "../box/box"
import MuiIcon from "../mui-icon"
import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"

const DragHandle = SortableHandle(({classes, isLeader, name, sortIndex}) => (
  <div className={classes.dragHandle}>
    {isLeader ? (
      <Tooltip
        placement="bottom-start"
        title="This is a split testing group. Modifying the order will modify the order of all templates in the group."
      >
        <div className={classes.centerAlignContent}>
          <Typography>{sortIndex + 1}.</Typography>
          <GroupWorkIcon className={classes.groupWorkIcon} size={20} />
          <Typography>{name}</Typography>
        </div>
      </Tooltip>
    ) : (
      <Typography>
        {sortIndex + 1}. {name}
      </Typography>
    )}
  </div>
))

const TargetingOrderRow = SortableElement(({classes, id, isLeader, name, sortIndex}) => (
  <div className={classes.templateRow}>
    <DragHandle classes={classes} isLeader={isLeader} name={name} sortIndex={sortIndex} />
    <div>
      <Link className={classes.link} to={`/admin/templates/${id}/targeting`}>
        <MuiIcon icon={<LinkIcon />} />
      </Link>
    </div>
  </div>
))

const TargetingOrderSortableContainer = SortableContainer(({classes, templates}) => (
  <div>
    {templates.map(({id, isSplitTestingGroupLeader, name}, index) => (
      <TargetingOrderRow
        classes={classes}
        id={id}
        index={index}
        isLeader={isSplitTestingGroupLeader}
        key={id}
        name={name}
        sortIndex={index}
      />
    ))}
  </div>
))

const nameForGroup = (groups, groupId, defaultVal) => {
  const group = groups.find(g => g.id === groupId) || {}

  return group.name ?? defaultVal
}

const TargetingOrder = () => {
  const [priorityTemplates, setPriorityTemplates] = useState([])
  const [targetedTemplates, setTargetingTemplates] = useState([])
  const classes = useStyles()

  useEffect(() => {
    const _fetchTemplates = async () => {
      const splitTestingGroups = await fetchSplitTestingGroups()
      const _priorityTemplates = await fetchTemplates({isTargeted: true, isPriority: true})
      const _targetedTemplates = await fetchTemplates({isTargeted: true, isPriority: false})

      setPriorityTemplates(
        _priorityTemplates.map(template =>
          !!template.splitTestingGroupId
            ? {
                ...template,
                name: nameForGroup(splitTestingGroups, template.splitTestingGroupId, template.name),
              }
            : template
        )
      )
      setTargetingTemplates(
        _targetedTemplates.map(template =>
          !!template.splitTestingGroupId
            ? {
                ...template,
                name: nameForGroup(splitTestingGroups, template.splitTestingGroupId, template.name),
              }
            : template
        )
      )
    }

    _fetchTemplates()
  }, [])

  const onSortEnd = ({oldIndex, newIndex}) => {
    const sortedTemplates = arrayMove(targetedTemplates, oldIndex, newIndex)
    const _sortTemplates = async () => {
      await sortTemplates(sortedTemplates.map(({id}, index) => ({id, targetingOrder: index + 1})))
    }

    setTargetingTemplates(sortedTemplates)
    _sortTemplates()
  }

  return (
    <>
      <TitleBar
        backButtonAttrs={{to: "/admin/templates", children: "Return to Campaigns Dashboard"}}
        title="Campaign Targeting Order"
      />

      <Padded>
        <Typography variant="h5">Targeting Order</Typography>
        <Box>
          <Grid container={true}>
            <Grid item={true} lg={6} md={12}>
              <Typography gutterBottom={true}>
                Each time targeting runs, each contact will be checked against each campaign in the
                targeting order, and the contact will be enrolled in the first campaign they match
                the conditions of. You can drag the campaigns to update this order.
              </Typography>
              <Typography gutterBottom={true}>
                Once a contact has been enrolled in a campaign that is part of the Targeting Order,
                they will not be checked against any other campaign in the Targeting Order, higher
                or lower. As a result, they will not be eligible for enrollment in any other
                campaigns in the Targeting Order.
              </Typography>
              <TargetingOrderSortableContainer
                classes={classes}
                onSortEnd={onSortEnd}
                templates={targetedTemplates}
                useDragHandle={true}
              />
            </Grid>
          </Grid>
        </Box>
      </Padded>
      <Padded>
        <Typography variant="h5">Priority Campaigns</Typography>
        <Box>
          <Grid container={true}>
            <Grid item={true} lg={6} md={12}>
              <Typography gutterBottom={true}>
                These are your priority campaigns. Campaigns in this list will always be sent when
                they match the campaigns targeting conditions.
              </Typography>
              {priorityTemplates.map(({id, isSplitTestingGroupLeader: isLeader, name}) => (
                <div className={classes.templateRow} key={id}>
                  {isLeader ? (
                    <div className={classes.centerAlignContent}>
                      <GroupWorkIcon className={classes.groupWorkIcon} size={20} />
                      <Typography>{name}</Typography>
                    </div>
                  ) : (
                    <Typography>{name}</Typography>
                  )}
                  <div>
                    <Link className={classes.link} to={`/admin/templates/${id}/targeting`}>
                      <MuiIcon icon={<LinkIcon />} />
                    </Link>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Padded>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  templateRow: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  centerAlignContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dragHandle: {
    cursor: "move",
    padding: `0 ${theme.spacing(1)}`,
    flex: 1,
  },
  groupWorkIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.text.primary,
    display: "inline-block",
    padding: `0 ${theme.spacing(1)}`,
    marginLeft: 10,
  },
}))

export default TargetingOrder
