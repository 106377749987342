import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, func, number, object, string} from "prop-types"
import {Component} from "react"
import {MdFileDownload as DownloadIcon} from "react-icons/md"
import {connect} from "react-redux"

import {cancelExport, exportRecords} from "./exporter-actions"

export class ExportButton extends Component {
  onExport = () => {
    const {fetchRecords, filename, params, sortHeaders, transform} = this.props

    this.props.exportRecords(fetchRecords, filename, params, sortHeaders, transform)
  }

  onCloseDialog = () => this.props.cancelExport()

  render() {
    const {classes, disabled, exportError, exportProgress, isExporting, title} = this.props

    return (
      <>
        <Button
          color="primary"
          disabled={disabled}
          onClick={this.onExport}
          size="small"
          variant="contained"
        >
          <DownloadIcon style={{marginRight: 10}} /> {title}
        </Button>
        <Dialog onClose={this.onCloseDialog} open={!!isExporting}>
          <DialogTitle>
            {exportError ? "An error occurred" : "Preparing your export..."}
          </DialogTitle>
          <DialogContent>
            <LinearProgress
              classes={{
                bar: classes.progressBar,
                bar1Determinate: cx({[classes.errorProgress]: exportError}),
              }}
              value={exportProgress}
              variant="determinate"
            />
            {exportError && (
              <Typography className={classes.errorText}>
                There was an error generating your export. Please try again later.
              </Typography>
            )}
          </DialogContent>
          {exportError && (
            <DialogActions>
              <Button className="cancel" color="primary" onClick={this.onCloseDialog} type="button">
                Cancel
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </>
    )
  }
}

ExportButton.propTypes = {
  cancelExport: func.isRequired,
  classes: object,
  disabled: bool,
  exportError: bool,
  exportProgress: number,
  exportRecords: func.isRequired,
  fetchRecords: func.isRequired,
  filename: string.isRequired,
  isExporting: bool,
  params: object,
  sortHeaders: func,
  title: string.isRequired,
  transform: func,
}

const styles = theme => ({
  progressBar: {
    transition: theme.transitions.create(["background-color", "transform", {easing: "linear"}]),
  },
  errorProgress: {
    backgroundColor: theme.palette.error.main,
  },
  errorText: {
    marginTop: theme.spacing(2),
  },
})

const mapStateToProps = ({exporter: {isExporting, exportError, exportProgress} = {}}) => ({
  exportError,
  exportProgress,
  isExporting,
})

const mapDispatchToProps = {
  exportRecords,
  cancelExport,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExportButton))
