import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material"
import {alpha, darken} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {format, parseISO} from "date-fns"
import {bool, func, object, string} from "prop-types"
import {useState} from "react"

import {validEmail} from "lib/field-validations"
import {formify} from "lib/hooks/use-form"
import {timezones} from "lib/timezones"

import AccessControlled, {controlAccess} from "../access-control/access-controlled"
import DangerButton from "../danger-button/danger-button"
import DatePicker from "../date-picker/date-picker"
import DOSelect from "../do-select/do-select"
import SaveButton from "../save-button/save-button"

const birthdateFormatter = date => (date instanceof Date ? format(date, "yyyy-MM-dd") : date)

const stringToLocalDate = ({value}) => {
  // NB: Value will be empty string on initialization, or an object if already parsed
  // NB: MUI's DatePicker expects a date with time in the current locale
  if (typeof value == "string" && value !== "") return parseISO(value)
  return value
}

const helpStyles = makeStyles(theme => ({
  link: {
    cursor: "pointer",
    lineHeight: "38px",
  },
  container: {
    padding: theme.spacing(),
    margin: `${theme.spacing()} 0`,
    backgroundColor: alpha(theme.palette.brand.lightGreen, 0.1),
    border: `1px solid ${theme.palette.brand.lightGreen}`,
    borderLeft: "none",
    borderRight: "none",
    "& h5": {
      margin: 0,
      color: darken(theme.palette.brand.lightGreen, 0.3),
      fontSize: theme.typography.body2.fontSize,
    },
    "& h5:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
}))

const MessagingStateHelp = ({flavor, field}) => {
  const isSMS = flavor === "sms"
  const terms = isSMS
    ? {label: "mobile phone", messageType: "SMSs"}
    : {label: "email", messageType: "emails"}
  const [expanded, setExpanded] = useState(false)
  const classes = helpStyles()
  const verified = field(`${flavor}Verified`).checked
  const optedIn = field(`${flavor}OptedIn`).checked
  const authorized = field(`${flavor}Authorized`).checked
  const hasAddress = field(isSMS ? "phoneMobile" : "email").value

  const methods = !hasAddress
    ? []
    : [
        verified && optedIn && <b key="verified">campaign {terms.messageType}</b>,
        verified && authorized && <b key="optedIn">transactional {terms.messageType}</b>,
        authorized && <b key="authorized">authorization {terms.messageType}</b>,
      ].filter(Boolean)

  return (
    <>
      <Link className={classes.link} onClick={() => setExpanded(!expanded)}>
        What's this?
      </Link>
      <FormHelperText>
        {methods.length > 0 ? (
          <>
            This contact can receive{" "}
            {methods.flatMap((method, i) => {
              if (methods.length > 2 && i < methods.length - 1) return [method, ", "]
              else if (methods.length > 1 && i === methods.length - 1) return [" and ", method]
              else return [method]
            })}
          </>
        ) : (
          <>
            This contact <b>can not receive any {terms.messageType}</b>
          </>
        )}
        .
      </FormHelperText>
      <Collapse in={expanded}>
        <div className={classes.container}>
          <h5>Verified</h5>
          The contact has confirmed they are receiving {terms.messageType} sent to that{" "}
          {terms.label} by clicking a link sent to that address.
          <h5>Opted in</h5>
          The contact has opted in to receive {terms.messageType} from campaigns. A contact{" "}
          <b>must</b> be both verified and opted in to receive {terms.messageType} from campaigns.
          <h5>Authorized</h5>
          It is safe to send authorization tokens to the contact's {terms.label}.
        </div>
      </Collapse>
    </>
  )
}

MessagingStateHelp.propTypes = {
  field: func.isRequired,
  flavor: string.isRequired,
}

const ContactForm = ({
  field,
  classes,
  handleSubmit,
  disabled,
  invalid,
  onOpenDeleteDialog,
  submitting,
  change,
}) => {
  const birthdateField = field("birthdate")
  const email = field("email")
  const uniqueId = field("uniqueId")

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={4}>
        <Grid item={true} lg={6} md={12}>
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            helperText="A unique ID is for cross-referencing contacts in your system. If no unique ID is entered, we'll default to email."
            label="Unique ID"
            {...uniqueId}
          />
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="First Name"
            {...field("nameFirst")}
          />
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="Last Name"
            {...field("nameLast")}
          />
          <FormControl className={classes.field} disabled={disabled} fullWidth={true}>
            <InputLabel htmlFor="timezone">Timezone</InputLabel>
            <DOSelect id="timezone" {...field("timezone", {exclude: ["helperText"]})}>
              {timezones.map(timezone => (
                <MenuItem key={timezone.value} value={timezone.value}>
                  {timezone.text}
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
          <DatePicker
            {...birthdateField}
            className={classes.field}
            disableFuture={true}
            value={stringToLocalDate(birthdateField)}
            label="Date of Birth"
            mask="__/__/____"
          />
        </Grid>
        <Grid item={true} lg={6} md={12}>
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="Email"
            {...email}
            onChange={event => {
              email.onChange(event)
              change("uniqueId", uniqueId.value)
            }}
          />
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={disabled}
                  {...field("emailVerified", {bool: true, exclude: ["error", "helperText"]})}
                />
              }
              label="Verified"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={disabled}
                  {...field("emailOptedIn", {bool: true, exclude: ["error", "helperText"]})}
                />
              }
              label="Opted in"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={disabled}
                  {...field("emailAuthorized", {bool: true, exclude: ["error", "helperText"]})}
                />
              }
              label="Authorized"
            />
          </FormControl>
          <MessagingStateHelp field={field} flavor="email" />
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="Home Phone"
            {...field("phoneHome")}
          />
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="Mobile Phone"
            {...field("phoneMobile")}
          />
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={disabled}
                  {...field("smsVerified", {bool: true, exclude: ["error", "helperText"]})}
                />
              }
              label="Verified"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={disabled}
                  {...field("smsOptedIn", {bool: true, exclude: ["error", "helperText"]})}
                />
              }
              label="Opted In"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={disabled}
                  {...field("smsAuthorized", {bool: true, exclude: ["error", "helperText"]})}
                />
              }
              label="Authorized"
            />
          </FormControl>
          <MessagingStateHelp field={field} flavor="sms" />
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="Work Phone"
            {...field("phoneWork")}
          />
        </Grid>
      </Grid>
      <div className={classes.formActions}>
        {onOpenDeleteDialog && (
          <AccessControlled requiredPermissions="contacts:delete">
            <DangerButton onClick={onOpenDeleteDialog}>Delete Contact</DangerButton>
          </AccessControlled>
        )}
        <SaveButton disabled={disabled || invalid} failed={invalid} submitting={submitting} />
      </div>
    </form>
  )
}

ContactForm.propTypes = {
  change: func.isRequired,
  classes: object,
  disabled: bool,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  invalid: bool.isRequired,
  onOpenDeleteDialog: func,
  submitting: bool.isRequired,
}

export default controlAccess({requiredPermissions: "contacts:edit"})(
  formify({
    enableReinitialize: true,
    validators: {email: [validEmail]},
    parse: {birthdate: birthdateFormatter},
  })(ContactForm)
)
