import {array, element, elementType, func, node, oneOfType, shape, string} from "prop-types"
import * as React from "react"
import {connect} from "react-redux"
import {Redirect, Route} from "react-router-dom"

import {getCurrentUser} from "../../actions/session-actions"
import {isUserPermitted} from "./access-controlled"

const RedirectToNotPermitted = () => <Redirect to="/not-permitted" />

export class AccessControlled extends React.Component {
  componentDidMount = () => !this.props.currentUser && this.props.getCurrentUser()

  renderComponent = props => {
    const {component: Component} = this.props

    return <Component {...props} />
  }

  render() {
    const {component, requiredPermissions, currentUser, ...rest} = this.props

    if (!currentUser) return null

    if (isUserPermitted(currentUser, requiredPermissions))
      return <Route {...rest} render={this.renderComponent} />

    return <Route component={RedirectToNotPermitted} />
  }
}

AccessControlled.propTypes = {
  component: oneOfType([element, elementType, node]).isRequired,
  currentUser: shape({
    permissions: array,
  }),
  getCurrentUser: func.isRequired,
  requiredPermissions: oneOfType([array, string]).isRequired,
}

export default connect(({session: {user}}) => ({currentUser: user}), {getCurrentUser})(
  AccessControlled
)
