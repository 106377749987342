import {TableRow} from "@mui/material"
import {array, object} from "prop-types"

import {distanceDateTime} from "lib/date-time-formatters"
import {fullName} from "lib/names"

import TableCellLink from "../table-cell-link/table-cell-link"

const booleanToYesNo = val => (val ? "Yes" : "No")

const valueForContact = (contact, field) => {
  switch (field) {
    case "nameFull":
      return fullName(contact)
    case "insertedAt":
    case "updatedAt":
      return distanceDateTime(contact[field])
    case "emailAuthorized":
    case "emailOptedIn":
    case "emailVerified":
    case "smsAuthorized":
    case "smsOptedIn":
    case "smsVerified":
      return booleanToYesNo(contact[field])
    default:
      return contact[field] || ""
  }
}

const ContactsListRow = ({classes, columns, row: contact}) => (
  <TableRow className={classes.link}>
    {columns.map(({field}) => (
      <TableCellLink
        className={`contact-field-cell-${field}`}
        component="td"
        key={`cfc-${field}`}
        scope="row"
        to={`/admin/contacts/${contact.id}`}
      >
        {valueForContact(contact, field)}
      </TableCellLink>
    ))}
  </TableRow>
)

ContactsListRow.propTypes = {
  classes: object,
  columns: array,
  row: object,
}

export default ContactsListRow
