import {ConnectedRouter} from "connected-react-router"
import {PureComponent} from "react"
import {DndProvider} from "react-dnd"
import {HTML5Backend} from "react-dnd-html5-backend"
import {Provider} from "react-redux"
import {Redirect, Route, Switch} from "react-router-dom"

import NotPermitted from "components/access-control/not-permitted"
import A11yMessage from "components/accessability/a11y-message"
import AdminRouter from "components/admin-router/admin-router"
import AppMessages from "components/app-messages/app-messages"
import CampaignPackageContainer from "components/campaign-review/campaign-package-container"
import CampaignReview from "components/campaign-review/campaign-review"
import CenteredBox from "components/centered-box/centered-box"
import EmailSubscriptionContainer from "components/contacts/email-subscriptions-container"
import ThemedContentContainerPreview from "components/cross-channel-engagement/themed-content-container-preview"
import ThemedCrossChannelEngagement from "components/cross-channel-engagement/themed-cross-channel-engagement"
import EmailSMSVerification from "components/email-sms-verification/email-sms-verification"
import {withErrorBoundary} from "components/error-boundary/error-boundary"
import ForgotPasswordContainer from "components/forgot-password/forgot-password-container"
import JourneyContainer from "components/journeys/journey-container"
import LandingPageContainer from "components/landing-page/landing-page-container"
import LoginContainer from "components/login/login-container"
import PagePreview from "components/page-preview/page-preview"
import ResetPasswordContainer from "components/reset-password/reset-password-container"

import store, {history} from "reducers/store"

import SocketManager from "contexts/socket-manager"

import AdminRoute from "./admin-route"
import DeprecatedPageRedirector from "./deprecated-page-redirector"
import JourneyRoute from "./journey-route"
import validateSubdomain from "./validate-subdomain"

history.listen((location, action) => {
  if (action !== "REPLACE") window.scroll(0, 0)
})

const GeneralRouteFallback = () => (
  <CenteredBox
    errorMessage="We're sorry for the inconvenience. This error has been logged."
    title="Something went wrong!"
    variant="error"
  />
)

const RedirectToTemplates = () => <Redirect to="/admin/templates" />

// eslint-disable-next-line react/prefer-stateless-function
class Router extends PureComponent {
  // DragDropContext requires a class so it can get a ref on it
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <SocketManager>
            <ConnectedRouter history={history}>
              <>
                <A11yMessage />
                <AppMessages />
                <Switch>
                  <Route component={LoginContainer} path="/admin/login" />
                  <Route
                    component={ForgotPasswordContainer}
                    path="/admin/account/forgot-password"
                  />
                  <Route
                    component={ResetPasswordContainer}
                    path="/admin/account/reset-password/:resetToken"
                  />
                  <AdminRoute component={AdminRouter} path="/admin" />
                  <Route component={LandingPageContainer} path="/lp/:templateId" />
                  <Route
                    component={DeprecatedPageRedirector}
                    path="/journeys/:journeySlug/pages/:oldPageSlug"
                  />
                  <JourneyRoute
                    component={JourneyContainer}
                    path="/journeys/:journeySlug/:pageSlug"
                  />
                  <JourneyRoute component={JourneyContainer} path="/journeys/:journeySlug" />
                  <Route
                    component={EmailSMSVerification}
                    path="/contacts/:contactId/verification/:type/:token"
                  />
                  <Route
                    component={EmailSubscriptionContainer}
                    path="/contacts/:id/email-subscription"
                  />
                  <Route component={CampaignPackageContainer} path="/approvals/:token/package" />
                  <Route component={CampaignReview} path="/approvals/:token" />
                  <Route
                    component={ThemedContentContainerPreview}
                    path="/previews/content-container/:contentContainerId"
                  />
                  <Route component={PagePreview} path="/previews/page/:pageId" />
                  <JourneyRoute
                    component={ThemedCrossChannelEngagement}
                    path="/content-containers/:contentContainerId/contacts/:contactId"
                  />
                  <JourneyRoute
                    component={ThemedCrossChannelEngagement}
                    path="/content-containers/:contentContainerId"
                  />
                  <Route component={NotPermitted} path="/not-permitted" />
                  <Route component={RedirectToTemplates} exact={true} path="/" />
                </Switch>
              </>
            </ConnectedRouter>
          </SocketManager>
        </Provider>
      </DndProvider>
    )
  }
}

export default validateSubdomain(withErrorBoundary(Router, <GeneralRouteFallback />))
