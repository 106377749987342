const uploadFileWithPolicy = (file, policy, options) => {
  const body = new FormData()

  for (const key in policy) body.append(key, policy[key])

  if (options.extraAttrs)
    for (const key in options.extraAttrs) body.append(key, options.extraAttrs[key])

  body.append("file", file, file.name)

  return window.fetch(process.env.REACT_APP_UPLOAD_URL, {method: "POST", body})
}

export default uploadFileWithPolicy
