import {kebabCase} from "lodash"
import {stringify} from "query-string"

import {toSnakeCase} from "lib/case-converter"

import apiReq from "./api-req"

export function authenticate(attrs, options = {}) {
  return apiReq("oauth2/token", {
    ...options,
    method: "POST",
    body: {
      ...attrs,
      grantType: "password",
    },
  })
}

export function deleteToken() {
  return apiReq("oauth2/token", {
    method: "DELETE",
  })
}

export function addAction(action) {
  return apiReq(`analytics`, {
    method: "POST",
    body: action,
  })
}

export function fetchActions(queryParams) {
  return apiReq("analytics", {
    queryParams,
  })
}

export function fetchActionCounts(queryParams) {
  return apiReq("analytics", {
    queryParams,
    method: "HEAD",
  })
}

export function fetchActionDownloadUrl(actionId, queryParams) {
  return apiReq(`analytics/${actionId}/show-download-url`, {
    queryParams,
  })
}

export function fetchContactActions({contactId, ...queryParams}, options) {
  return apiReq(`contacts/${contactId}/actions`, {
    ...options,
    queryParams,
  })
}

export function fetchContactActionCtas(contactId) {
  return apiReq(`contacts/${contactId}/actions/content-blocks/ctas`, {
    ignoreCaseConversionForPaths: [/\d\.data/],
  })
}

export function fetchJourney(slug) {
  return apiReq(`journeys/slug/${slug}`, {
    ignoreCaseConversionForKeys: ["meta_public", "meta_private", "survey", "rewards"],
  })
}

export function fetchJourneyById(id) {
  return apiReq(`journeys/${id}`)
}

export function fetchJourneyAnalytics(id) {
  return apiReq(`analytics?journey_id=${id}&preload[]=page`)
}

export function fetchJourneys(queryParams) {
  return apiReq("journeys", {
    queryParams,
  })
}

export function fetchJourneySurveyStatus(journeyId, contentBlockId) {
  return apiReq(`journeys/${journeyId}/surveys/${contentBlockId}/answers`)
}

export function createJourney(templateId, attrs, options = {}) {
  return apiReq(`templates/${templateId}/journeys`, {
    ...options,
    method: "POST",
    body: attrs,
  })
}

export function updateJourney(templateId, journeyId, attrs) {
  return apiReq(`templates/${templateId}/journeys/${journeyId}`, {
    method: "PUT",
    body: attrs,
  })
}

export function deleteJourney(templateId, journeyId) {
  return apiReq(`templates/${templateId}/journeys/${journeyId}`, {
    method: "DELETE",
  })
}

const templateKeysToIgnoreForCaseConversion = ["blocks", "entityMap", "theme"]
const templatePathsToIgnoreForCaseConversion = [/theme_overrides\..+/, /themeOverrides\..+/]

export function fetchTemplates(options = {}) {
  const {withFetchResponse = false, ...queryParams} = options

  return apiReq("templates", {
    queryParams,
    withFetchResponse,
    ignoreCaseConversionForKeys: templateKeysToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: templatePathsToIgnoreForCaseConversion,
  })
}

export function headTemplateJourneys(templateId) {
  return apiReq(`templates/${templateId}/journeys`, {
    method: "HEAD",
    withFetchResponse: true,
  })
}

export function fetchTemplateJourneys(templateId, queryParams) {
  return apiReq(`templates/${templateId}/journeys`, {
    queryParams,
    withFetchResponse: true,
  })
}

export function fetchTemplateActions({templateId, ...queryParams}, options) {
  return apiReq(`templates/${templateId}/actions`, {
    queryParams,
    ...options,
  })
}

export function fetchTemplateLaunchStatus(templateId) {
  return apiReq(`templates/${templateId}/launch-status`)
}

export function fetchTemplate(id, queryParams = {}) {
  return apiReq(`templates/${id}`, {
    queryParams,
    ignoreCaseConversionForKeys: templateKeysToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: templatePathsToIgnoreForCaseConversion,
  })
}

export function createTemplate(attrs) {
  return apiReq("templates", {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: templateKeysToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: templatePathsToIgnoreForCaseConversion,
  })
}

export function createCampaign(attrs) {
  return apiReq("campaigns", {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["content", "content_variables", "blocks", "entityMap"],
  })
}

export function fetchSurveys() {
  return apiReq("content-blocks?type=survey")
}

export function fetchTemplatePages(templateId, params) {
  return apiReq(`templates/${templateId}/template_pages?${stringify(toSnakeCase(params))}`, {
    ignoreCaseConversionForKeys: ["content", "content_variables"],
    ignoreCaseConversionForPaths: [/content_blocks\.\d\.data/],
  })
}

export function fetchTemplateSurveyInsights(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/survey-widget`, {
    method: "GET",
    queryParams,
  })
}

export function fetchWhichTemplateContentBlocks(templateId, queryParams) {
  return apiReq(`templates/${templateId}/which-content-blocks`, {
    queryParams,
    ignoreCaseConversionForPaths: [/.*/],
  })
}

export function fetchWebhookLogs(queryParams) {
  return apiReq("webhook-logs", {queryParams, withFetchResponse: true})
}

export function fetchWebhookLogDetails(webhookLogId) {
  return apiReq(`webhook-logs/${webhookLogId}/details`, {
    ignoreCaseConversionForKeys: ["body", "headers"],
  })
}

export function updateTemplate(templateId, attrs) {
  return apiReq(`templates/${templateId}`, {
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: templateKeysToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: templatePathsToIgnoreForCaseConversion,
  })
}

export function sortTemplates(templates) {
  return apiReq(`templates/sort`, {
    method: "PUT",
    body: templates,
    ignoreCaseConversionForKeys: ["blocks", "entityMap"],
  })
}

export function deleteTemplate(templateId) {
  return apiReq(`templates/${templateId}`, {
    method: "DELETE",
  })
}

export function fetchCsvExport(templateId) {
  return apiReq(`templates/${templateId}/report`)
}

export function fetchSurveyAnswers(templateId) {
  return apiReq(`templates/${templateId}/survey-results`)
}

export function fetchCardOnFileTargetingValues() {
  return apiReq(`widgets/card-on-file/targeting-values`)
}

export function fetchDirectDepositEmployers() {
  return apiReq(`widgets/direct-deposit/employers`)
}

export function createTemplateMessage(templateId, attrs) {
  return apiReq(`templates/${templateId}/template_messages`, {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["content_variables", "body", "subject"],
  })
}

export function fetchTemplateMessage(templateId, templateMessageId) {
  return apiReq(`templates/${templateId}/template_messages/${templateMessageId}`, {
    ignoreCaseConversionForKeys: ["content_variables", "body", "subject"],
  })
}

export function updateTemplateMessage(templateId, templateMessageId, attrs) {
  return apiReq(`templates/${templateId}/template_messages/${templateMessageId}`, {
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: ["content_variables", "body", "subject"],
  })
}

export function createTemplatePage(templateId, attrs, options = {}) {
  return apiReq(`templates/${templateId}/template_pages`, {
    ...options,
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["content"],
  })
}

export function createPage(attrs, options = {}) {
  return apiReq("pages", {
    ...options,
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["content"],
  })
}

export function fetchPages(queryParams) {
  return apiReq("pages", {
    queryParams,
  })
}

export function fetchPageByOldSlug({journeySlug, oldPageSlug}) {
  return apiReq(`pages/${oldPageSlug}/slug?journey_slug=${journeySlug}`)
}

export function updatePage(pageId, attrs) {
  return apiReq(`pages/${attrs["id"] || pageId}`, {
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: ["content"],
  })
}

export function deletePage(pageId) {
  return apiReq(`pages/${pageId}`, {
    method: "DELETE",
  })
}

export function duplicatePage(pageId) {
  return apiReq(`pages/${pageId}/duplicate`, {
    method: "POST",
  })
}

export function duplicateMessage(messageId, attrs) {
  return apiReq(`messages/${messageId}/duplicate`, {
    method: "POST",
    body: attrs,
  })
}

export function addPageToTemplate(templateId, attrs, options = {}) {
  return apiReq(`templates/${templateId}/pages`, {
    ...options,
    method: "POST",
    body: attrs,
  })
}

export function updateTemplatePage(templateId, templatePageId, attrs) {
  return apiReq(`templates/${templateId}/template_pages/${templatePageId}`, {
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: ["content_variables"],
  })
}

export function removeTemplatePage(templateId, templatePageId) {
  return apiReq(`templates/${templateId}/template_pages/${templatePageId}`, {
    method: "DELETE",
  })
}

export function sortTemplatePages(templateId, templatePages) {
  return apiReq(`templates/${templateId}/template_pages/sort`, {
    method: "PUT",
    body: templatePages,
    ignoreCaseConversionForKeys: ["content", "content_variables"],
  })
}

export function fetchFiles(queryParams) {
  return apiReq("files", {
    queryParams,
    ignoreCaseConversionForKeys: ["upload_policy"],
  })
}

export function fetchFile(fileId) {
  return apiReq(`files/${fileId}`, {
    ignoreCaseConversionForKeys: ["upload_policy"],
  })
}

export function createFile(file) {
  return apiReq("files", {
    method: "POST",
    body: file,
    ignoreCaseConversionForKeys: ["upload_policy"],
  })
}

export function updateFile(fileId, attrs) {
  return apiReq(`files/${fileId}`, {
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: ["upload_policy"],
  })
}

export function deleteFile(fileId) {
  return apiReq(`files/${fileId}`, {
    method: "DELETE",
  })
}

export function fetchContentRelatedToFile(fileId) {
  return apiReq(`files/${fileId}/related-content`)
}

export function fetchUrlHeaders(url, options = {}) {
  return apiReq("widgets/webpage/url-headers", {
    ...options,
    queryParams: {
      url,
    },
  })
}

export function fetchUserInfo() {
  return apiReq("oauth2/userinfo", {
    ignoreCaseConversionForKeys: [
      "config",
      "features",
      "contact_field_map",
      "account_field_map",
      "overrides",
    ],
  })
}

export function fetchUsers(options = {}) {
  return apiReq("users", options)
}

export function createUser(user) {
  return apiReq("users", {
    method: "POST",
    body: user,
  })
}

export function deleteUser(userId) {
  return apiReq(`users/${userId}`, {
    method: "DELETE",
  })
}

export function fetchCurrentUser() {
  return apiReq("current_user", {
    ignoreCaseConversionForKeys: ["permissions"],
  })
}

export function getCurrentUserAvatarUploadPolicy(attrs) {
  return apiReq("current_user/avatar-upload-policy", {
    queryParams: attrs,
    skipCamelCase: true,
  })
}

export function updateCurrentUser(attrs, options = {}) {
  return apiReq("current_user", {
    ...options,
    method: "PUT",
    body: attrs,
  })
}

export function updateUser(userId, attrs) {
  return apiReq(`users/${userId}`, {
    method: "PUT",
    body: attrs,
  })
}

export function createPasswordResetToken(attrs) {
  return apiReq(`users/reset-token`, {
    method: "POST",
    body: attrs,
  })
}

export function resetPassword(attrs, options = {}) {
  return apiReq(`users/reset-password`, {
    ...options,
    method: "POST",
    body: attrs,
  })
}

export function fetchObjectives() {
  return apiReq("objectives")
}

export function fetchObjective(objectiveId) {
  return apiReq(`objectives/${objectiveId}`)
}

export function createObjective(objective) {
  return apiReq("objectives", {
    method: "POST",
    body: objective,
  })
}

export function updateObjectives(objectives) {
  return apiReq("objectives", {
    method: "PATCH",
    body: objectives,
  })
}

export function fetchLandingPage(templateId, options = {}) {
  return apiReq(`templates/${templateId}/landing-page`, {
    ...options,
    ignoreCaseConversionForKeys: ["content"],
  })
}

export function createOrUpdateLandingPage(templateId, attrs) {
  const method = attrs.id ? "PUT" : "POST"

  return apiReq(`templates/${templateId}/landing-page`, {
    method,
    body: attrs,
    ignoreCaseConversionForKeys: ["content"],
  })
}

export function sendMobileAppDownloadLink(attrs, queryParams) {
  return apiReq(`mobile-app-downloads/send-link`, {
    method: "POST",
    body: attrs,
    queryParams,
  })
}

export function fetchTemplateMessages(templateId) {
  return apiReq(`templates/${templateId}/template_messages`, {
    ignoreCaseConversionForKeys: ["body", "subject", "content_variables"],
  })
}

export function createMessage(attrs) {
  return apiReq("messages", {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["body", "subject"],
  })
}

export function updateMessage(messageId, attrs) {
  return apiReq(`messages/${messageId}`, {
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: ["body", "subject"],
  })
}

export function deleteMessage(messageId) {
  return apiReq(`messages/${messageId}`, {
    method: "DELETE",
  })
}

export function updateContentContainer(engagementChannelId, contentContainerId, attrs) {
  return apiReq(
    `engagement-channels/${engagementChannelId}/content-containers/${contentContainerId}`,
    {
      method: "PUT",
      body: attrs,
      ignoreCaseConversionForKeys: ["body", "subject"],
    }
  )
}

export function duplicateTemplatePage(templateId, templatePageId) {
  return apiReq(`templates/${templateId}/template_pages/${templatePageId}/duplicate`, {
    method: "POST",
    ignoreCaseConversionForKeys: ["content_variables"],
  })
}

export function duplicateTemplateMessage(templateId, templateMessageId, attrs) {
  return apiReq(`templates/${templateId}/template_messages/${templateMessageId}/duplicate`, {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["body", "subject", "content_variables"],
  })
}

export function removeTemplateMessage(templateId, templateMessageId) {
  return apiReq(`templates/${templateId}/template_messages/${templateMessageId}`, {
    method: "DELETE",
  })
}

export function sortMessages(templateId, pageId, messages) {
  return apiReq(`templates/${templateId}/template_messages/sort`, {
    method: "PUT",
    body: messages,
    queryParams: {
      pageId,
    },
    ignoreCaseConversionForKeys: ["body", "subject", "content_variables"],
  })
}

// TODO: atomic-assets this function replaces sortMessages
export function sortTemplateMessages(templateId, messages) {
  return apiReq(`templates/${templateId}/template_messages/sort`, {
    method: "PUT",
    body: messages,
    ignoreCaseConversionForKeys: ["body", "subject", "content_variables"],
  })
}

export function sendDraftMessage(attrs) {
  return apiReq("messages/send", {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["body", "subject", "content_variables"],
    ignoreCaseConversionForPaths: [/contentBlocks\.\d\.data/],
  })
}

export function batchUploadJourneys(templateId, attrs, options = {}) {
  return apiReq(`templates/${templateId}/journeys`, {
    ...options,
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["journeys"],
  })
}

export function batchUploadContacts(attrs, options = {}) {
  return apiReq(`contacts`, {
    ...options,
    method: "POST",
    body: attrs,
    skipSnakeCase: true,
  })
}

export function getBatch(batchId) {
  return apiReq(`batches/${batchId}`)
}

export function scheduleBatchUpload(attrs) {
  return apiReq("batches", {
    method: "POST",
    body: attrs,
    skipSnakeCase: true,
  })
}

export function fetchBatches(queryParams, options = {}) {
  return apiReq("batches", {
    ...options,
    queryParams,
    ignoreCaseConversionForKeys: ["results"],
  })
}

export function deleteBatch(batchId) {
  return apiReq(`batches/${batchId}`, {
    method: "DELETE",
  })
}

export function fetchTimelineMarkers(templateId) {
  return apiReq(`templates/${templateId}/timeline-markers`)
}

export function createTimelineMarker(templateId, attrs) {
  return apiReq(`templates/${templateId}/timeline-markers`, {
    method: "POST",
    body: attrs,
  })
}

export function updateTimelineMarker(templateId, timelineMarkerId, attrs) {
  return apiReq(`templates/${templateId}/timeline-markers/${timelineMarkerId}`, {
    method: "PUT",
    body: attrs,
  })
}

export function deleteTimelineMarker(templateId, timelineMarkerId) {
  return apiReq(`templates/${templateId}/timeline-markers/${timelineMarkerId}`, {
    method: "DELETE",
  })
}

export function fetchContacts(queryParams, options) {
  return apiReq("contacts", {
    queryParams,
    ...options,
  })
}

export function fetchContact(contactId) {
  return apiReq(`/contacts/${contactId}`)
}

export function deleteContact(contactId) {
  return apiReq(`/contacts/${contactId}`, {
    method: "DELETE",
  })
}

export function fetchContactAccountByNumber(contactId, accountNumber) {
  return apiReq(`contacts/${contactId}/accounts/${accountNumber}`)
}

export function createService(attrs) {
  return apiReq("services", {
    method: "POST",
    body: attrs,
  })
}

export function fetchServices(queryParams, options) {
  return apiReq("services", {
    queryParams,
    ...options,
  })
}

export function fetchProducts(queryParams, options) {
  return apiReq("products", {
    queryParams,
    ...options,
  })
}

export function createProduct(attrs, options = {}) {
  return apiReq("products", {
    ...options,
    method: "POST",
    body: attrs,
  })
}

export function updateProduct(productId, attrs, options = {}) {
  return apiReq(`products/${productId}`, {
    ...options,
    method: "PUT",
    body: attrs,
  })
}

export function deleteProduct(productId, options = {}) {
  return apiReq(`products/${productId}`, {
    ...options,
    method: "DELETE",
  })
}

export function fetchVerification(contactId, type, token, queryParams) {
  return apiReq(`contacts/${contactId}/verification/${type}/${token}`, {
    method: "PUT",
    queryParams,
  })
}

export function resendVerification(contactId, type, queryParams) {
  return apiReq(`contacts/${contactId}/verification/${type}/resend`, {
    method: "POST",
    queryParams,
  })
}

export function updateContact(contactId, attrs, options = {}) {
  return apiReq(`/contacts/${contactId}`, {
    ...options,
    method: "PUT",
    body: attrs,
  })
}

export function createContact(attrs, options = {}) {
  return apiReq("contacts", {
    ...options,
    method: "POST",
    body: attrs,
  })
}

export function fetchContactSubscription(contactId) {
  return apiReq(`contacts/${contactId}/subscription`)
}

export function updateContactSubscription(contactId, body, queryParams) {
  return apiReq(`contacts/${contactId}/subscription`, {
    method: "PUT",
    body,
    queryParams,
  })
}

export function fetchContactObjectives(contactId) {
  return apiReq(`contacts/${contactId}/objectives`)
}

export function fetchContactAccounts(contactId) {
  return apiReq(`contacts/${contactId}/accounts`)
}

export function updateContactObjectiveStatus(contactId, objectiveId, status) {
  return apiReq(`contacts/${contactId}/objectives/${objectiveId}`, {
    method: "PUT",
    body: {status},
  })
}

const teamKeysToIgnoreForCaseConversion = [
  "address2",
  "config",
  "features",
  "contact_field_map",
  "account_field_map",
]
const teamPathsToIgnoreForCaseConversion = [/theme_styles\..+/, /themeStyles\..+/]

export function fetchCurrentTeam(queryParams = {}) {
  return apiReq("current_team", {
    queryParams: queryParams,
    ignoreCaseConversionForKeys: teamKeysToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: teamPathsToIgnoreForCaseConversion,
  })
}

export function updateCurrentTeam(attrs, options = {}) {
  return apiReq("current_team", {
    ...options,
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: teamKeysToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: teamPathsToIgnoreForCaseConversion,
  })
}

export function getCurrentTeamLogoUploadPolicy(attrs) {
  return apiReq("current_team/logo-upload-policy", {
    queryParams: attrs,
    skipCamelCase: true,
  })
}

export function getCurrentTeamFaviconUploadPolicy(attrs) {
  return apiReq("current_team/favicon-upload-policy", {
    queryParams: attrs,
    skipCamelCase: true,
  })
}

export function settingsEmailTest(attrs, options = {}) {
  return apiReq("settings/email-test", {
    ...options,
    method: "POST",
    body: attrs,
  })
}

export function fetchClients() {
  return apiReq("oauth2/clients")
}

export function updateClient(id, attrs) {
  return apiReq(`oauth2/clients/${id}`, {
    method: "PUT",
    body: attrs,
  })
}

export function createClient() {
  return apiReq("oauth2/clients", {
    method: "POST",
  })
}

export function fetchHouseholdContacts(contactId, queryParams) {
  return apiReq(`contacts/${contactId}/household`, {queryParams})
}

export function fetchFonts(queryParams) {
  return apiReq("fonts", {withFetchResponse: true, queryParams})
}

export function fetchFont(family, queryParams) {
  return apiReq(`fonts/${family}`, {queryParams})
}

export function fetchKnownMetaKeys() {
  return apiReq("contacts/accounts/metadata", {
    ignoreCaseConversionForKeys: ["meta_public", "meta_private"],
  })
}

export function fetchSshKeys() {
  return apiReq("settings/public-keys")
}

export function createSshKey(attrs) {
  return apiReq("settings/public-keys", {
    method: "POST",
    body: attrs,
  })
}

export function deleteSshKey(id) {
  return apiReq(`settings/public-keys/${id}`, {
    method: "DELETE",
  })
}

export function fetchPgpKeys() {
  return apiReq("settings/pgp-keys")
}

export function createPgpKey(attrs) {
  return apiReq("settings/pgp-keys", {
    method: "POST",
    body: attrs,
  })
}

export function deletePgpKey(id) {
  return apiReq(`settings/pgp-keys/${id}`, {
    method: "DELETE",
  })
}

export function getCmcFlexDiagnostics(attrs) {
  return apiReq(`settings/cmc-flex-diagnostics`, {method: "POST", body: attrs})
}

export function requestWebhookTest(attrs) {
  return apiReq(`settings/webhook-test`, {method: "POST", body: attrs})
}

// NB: Ignoring all case conversion for paths in the body. Query params will still be converted to
// snake_case, see api-req.js `queryString` for more detail.
export function enroll(contentBlockId, attrs, options = {}) {
  return apiReq(`enrollment/${contentBlockId}/enroll`, {
    ...options,
    method: "POST",
    body: attrs,
    ignoreCaseConversionForPaths: [/\.*/],
  })
}

export function fetchTemplateTargeting(templateId) {
  return apiReq(`templates/${templateId}/targeting`)
}

export function updateTemplateTargeting(templateId, body) {
  return apiReq(`templates/${templateId}/targeting`, {
    method: "POST",
    body,
  })
}

export function requestTargetingImpact(templateId, attrs) {
  return apiReq(`templates/${templateId}/targeting/impact`, {
    method: "POST",
    body: attrs,
  })
}

export function cancelTargetingImpact(templateId) {
  return apiReq(`templates/${templateId}/targeting/cancel`, {
    method: "POST",
    body: {},
  })
}

export function createSplitTestingGroup(attrs) {
  return apiReq(`split_testing_groups`, {
    method: "POST",
    body: attrs,
  })
}

export function fetchSplitTestingGroups(queryParams) {
  return apiReq(`split_testing_groups`, {
    method: "GET",
    queryParams,
  })
}

export function createEvent(attrs, options = {}) {
  return apiReq("events", {
    method: "POST",
    body: attrs,
    ...options,
  })
}

export function fetchEvents(queryParams, options) {
  return apiReq("events", {
    queryParams,
    ...options,
  })
}

export function fetchSessionLogs() {
  return apiReq("session-logs")
}

export function fetchScheduledMessages(templateId, journeyId) {
  return apiReq(`templates/${templateId}/journeys/${journeyId}/scheduled-messages`)
}

export function deleteScheduledMessage(templateId, journeyId, jobId) {
  return apiReq(`templates/${templateId}/journeys/${journeyId}/scheduled-messages/${jobId}`, {
    method: "DELETE",
  })
}

export function fetchPermissions() {
  return apiReq("permissions")
}

export function batchPutUserPermission(userId, attrs) {
  return apiReq(`users/${userId}/permissions`, {
    method: "PUT",
    body: attrs,
  })
}

export function sendOneTimePassword(journeyId, verificationMethod) {
  return apiReq(`journeys/${journeyId}/one-time-password`, {
    method: "POST",
    body: {verificationMethod},
  })
}

export function verifyOneTimePassword(journeyId, oneTimePassword) {
  return apiReq("oauth2/token", {
    method: "POST",
    body: {
      journeyId,
      oneTimePassword,
      grantType: "one_time_password",
    },
  })
}

export function fetchContentLibrary(queryParams) {
  return apiReq("content-library", {queryParams})
}

export function fetchPage(pageId, options = {}) {
  return apiReq(`pages/${pageId}`, {...options, ignoreCaseConversionForPaths: [/theme_styles\..+/]})
}

export function fetchContentContainer(engagementChannelId, contentContainerId, options = {}) {
  return apiReq(
    `engagement-channels/${engagementChannelId}/content-containers/${contentContainerId}`,
    {...options, ignoreCaseConversionForPaths: [/theme_styles\..+/]}
  )
}

export function fetchMessage(messageId, options = {}) {
  return apiReq(`messages/${messageId}`, options)
}

export function fetchContentLibraryItem(id, type) {
  return apiReq(`content-library/${id}?type=${type}`)
}

export function fetchTemplateGeneralInsights(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/general`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightCardOnFileOverview(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/card-on-file-overview`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightCardOnFileCompletions(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/card-on-file-merchant-detail`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightCardOnFileTotalSwitches(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/card-on-file-total-switches`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightCrossChannelBarchart(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/cross-channel-engagement-barchart`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightCrossChannelOverview(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/cross-channel-engagement-overview`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightDirectDepositDonut(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/direct-deposit-donut`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightEnrollmentWidget(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/enrollment-widget`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightJourneyDonut(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/journey-donut`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightRecurringEnrollmentsDonut(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/recurring-enrollments-donut`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightMessagePerformance(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/message-performance`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightMobileAppDonut(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/mobile-app-donut`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightObjectiveDonut(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/objective-donut`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightObjectivePerformance(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/objective-performance`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightOptInWidget(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/opt-in-widget`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightsPageStats(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/page-stats`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightProductTotalValue(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/product-total-value`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightSurveyCompletionDonut(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/survey-completion`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightSecurePersonalization(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/secure-personalization`, {
    method: "GET",
    queryParams,
  })
}

export function fetchInsightSurveyWidgetBarchart(templateId, queryParams) {
  return apiReq(`templates/${templateId}/insights/survey-widget-barchart`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsAddedValueByTime(queryParams) {
  return apiReq(`advanced-insights/added-value-by-time`, {
    ignoreCaseConversionForKeys: ["data"],
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsValueOfOpenedProductsOverTime(queryParams) {
  return apiReq(`advanced-insights/value-of-opened-products-over-time`, {
    ignoreCaseConversionForKeys: ["data"],
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedObjectiveCompletionRate(queryParams) {
  return apiReq(`advanced-insights/objective-completion-rate`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsOverallNumberOfCampaigns(queryParams) {
  return apiReq(`advanced-insights/overall-number-of-campaigns`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsCampaignEngagement(queryParams) {
  return apiReq(`advanced-insights/campaign-engagement`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsCampaignEnrollment(queryParams) {
  return apiReq(`advanced-insights/campaign-enrollment`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsCampaignPerformance(queryParams) {
  return apiReq(`advanced-insights/campaign-performance`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsDrivers() {
  return apiReq(`advanced-insights/drivers`)
}

export function fetchAdvancedInsightsObjectiveContent(queryParams) {
  return apiReq(`advanced-insights/objective-content`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsPages(queryParams) {
  return apiReq(`advanced-insights/pages`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsMessages(queryParams) {
  return apiReq(`advanced-insights/messages`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsMobileAppWidget(queryParams) {
  return apiReq(`advanced-insights/mobile-app-widget`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsCardOnFileWidget(queryParams) {
  return apiReq(`advanced-insights/card-on-file-widget`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsOptInWidget(queryParams) {
  return apiReq(`advanced-insights/opt-in-widget`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsEnrollmentWidget(queryParams) {
  return apiReq(`advanced-insights/enrollment-widget`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsPerformance(queryParams) {
  return apiReq(`advanced-insights/performance`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsObjectiveCompletionBenchmarks(queryParams) {
  return apiReq(`advanced-insights/objective-completion-benchmarks`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsObjectiveTotalValue(queryParams) {
  return apiReq(`advanced-insights/objective-total-value`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsGapAnalysis(queryParams) {
  return apiReq(`advanced-insights/gap-analysis`, {
    method: "GET",
    queryParams,
  })
}

export function fetchAdvancedInsightsTotalValueOfOpenedProducts(queryParams) {
  return apiReq(`advanced-insights/total-value-of-opened-products`, {
    method: "GET",
    queryParams,
  })
}

export function fetchTeamInsightsCompletedObjectives(queryParams) {
  return apiReq(`teams/insights/completed-objectives`, {
    method: "GET",
    queryParams,
  })
}

export function fetchTeamInsightsObjectiveContent(queryParams) {
  return apiReq(`teams/insights/objective-content`, {
    method: "GET",
    queryParams,
  })
}

export function fetchTeamInsightsOverallPerformance(queryParams) {
  return apiReq(`teams/insights/overall-performance`, {
    method: "GET",
    queryParams,
  })
}

export function fetchTeamInsightsCampaignPerformance(queryParams) {
  return apiReq(`teams/insights/campaign-performance`, {method: "GET", queryParams})
}

export function fetchTemplateActionCtas(templateId) {
  return apiReq(`templates/${templateId}/actions/content-blocks/ctas`, {
    ignoreCaseConversionForPaths: [/\d\.data/],
  })
}

const containerPath = entity => {
  // This is sometimes a container and sometimes a content block
  switch (true) {
    case entity._type === "content_container":
      return `engagement-channels/${entity.engagementChannelId}/content-containers/${entity.id}`
    case !!entity.containerId:
      return `${kebabCase(entity.containerType)}s/${entity.containerId}`
    default:
      return `${entity._type}s/${entity.id}`
  }
}

export function addContentBlock(container, body) {
  return apiReq(`${containerPath(container)}/content-blocks`, {
    method: "POST",
    body,
    ignoreCaseConversionForKeys: ["data", "styles"],
  })
}

export function fetchContentBlocks(queryParams) {
  return apiReq("content-blocks", {queryParams})
}

export function updateContentBlock(contentBlock, body) {
  return apiReq(`${containerPath(contentBlock)}/content-blocks/${contentBlock.id}`, {
    method: "PATCH",
    body,
    ignoreCaseConversionForKeys: ["data", "styles"],
  })
}

export function sortContentBlocks(container, body) {
  return apiReq(`${containerPath(container)}/content-blocks/sort`, {
    method: "PATCH",
    body,
    ignoreCaseConversionForKeys: ["data", "styles"],
  })
}

export function deleteContentBlock(contentBlock) {
  return apiReq(`${containerPath(contentBlock)}/content-blocks/${contentBlock.id}`, {
    method: "DELETE",
  })
}

export function createDirectDepositAccessToken(attrs) {
  return apiReq(`widgets/direct-deposit/access-token`, {
    method: "POST",
    body: attrs,
  })
}

export function sendCampaignForApproval(templateId, data) {
  return apiReq(`templates/${templateId}/approvals`, {
    method: "POST",
    body: data,
  })
}

export function fetchApproval(token) {
  return apiReq(`approvals/${token}/review`)
}

export function fetchPackageData(token) {
  return apiReq(`approvals/${token}/package`, {
    ignoreCaseConversionForKeys: templateKeysToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: templatePathsToIgnoreForCaseConversion,
  })
}

export function renewExpiredApproval(id) {
  return apiReq(`approvals/${id}/renew`, {
    method: "POST",
  })
}

export function submitApprovalVote(id, attrs) {
  return apiReq(`approvals/${id}`, {
    method: "PATCH",
    body: attrs,
  })
}

export function validateAddressWithUsps(attrs) {
  return apiReq(`usps/validate-address`, {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: ["street1", "street2"],
  })
}

//
// Rewards
//

export function fetchRewardSet(id, queryParams = {}) {
  return apiReq(`reward-sets/${id}`, {queryParams: queryParams})
}

export function fetchRewardSetCompletionInsights(id, queryParams = {}) {
  return apiReq(`reward-sets/${id}/completion-insights`, {queryParams: queryParams})
}

export function fetchRewardSets(queryParams = {}) {
  return apiReq(`reward-sets`, {queryParams: queryParams})
}

export function createRewardSet(attrs) {
  return apiReq(`reward-sets`, {
    method: "POST",
    body: attrs,
  })
}

export function updateRewardSet(attrs, id) {
  return apiReq(`reward-sets/${id}`, {
    method: "PATCH",
    body: attrs,
  })
}

export function deleteRewardSet(id) {
  return apiReq(`reward-sets/${id}`, {
    method: "DELETE",
  })
}

//
// Cross Channel Engagement (Engagement Channels)
//

const engagementChannelPathsToIgnoreForCaseConversion = [/theme_styles\..+/, /themeStyles\..+/]

export function fetchEngagementChannels(queryParams) {
  return apiReq("engagement-channels", {
    queryParams,
    ignoreCaseConversionForKeys: engagementChannelPathsToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: engagementChannelPathsToIgnoreForCaseConversion,
  })
}

export function createEngagementChannel(attrs) {
  return apiReq(`engagement-channels`, {
    method: "POST",
    body: attrs,
    ignoreCaseConversionForKeys: engagementChannelPathsToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: engagementChannelPathsToIgnoreForCaseConversion,
  })
}

export function updateEngagementChannel(id, attrs) {
  return apiReq(`engagement-channels/${id}`, {
    method: "PUT",
    body: attrs,
    ignoreCaseConversionForKeys: engagementChannelPathsToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: engagementChannelPathsToIgnoreForCaseConversion,
  })
}

export function deleteEngagementChannel(id) {
  return apiReq(`engagement-channels/${id}`, {
    method: "DELETE",
  })
}

export function fetchCrossChannelJourney(containerId, queryParams) {
  return apiReq(`content-containers/${containerId}/cross-channel-journey`, {
    queryParams,
    ignoreCaseConversionForKeys: engagementChannelPathsToIgnoreForCaseConversion,
    ignoreCaseConversionForPaths: engagementChannelPathsToIgnoreForCaseConversion,
  })
}

export function fetchContentContainerPreview(contentContainerId, options = {}) {
  return apiReq(`content-containers/${contentContainerId}/preview`, {
    ...options,
    ignoreCaseConversionForPaths: [/theme_styles\..+/],
  })
}

export function fetchNotificationSettings(queryParams, options) {
  return apiReq("notification-settings", {
    queryParams,
    ...options,
  })
}

export function updateNotificationSettings(notificationSettingId, attrs, options = {}) {
  return apiReq(`notification-settings/${notificationSettingId}`, {
    ...options,
    method: "PUT",
    body: attrs,
  })
}

export function loadTeamSeeding(yaml) {
  return apiReq("teams/seeding", {
    method: "POST",
    body: yaml,
  })
}
